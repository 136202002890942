/* eslint-disable jsx-a11y/alt-text */
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DiscountIcon from "@mui/icons-material/Discount";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import cliente from "../../icons/cliente.svg";
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { ptBR } from "@mui/x-data-grid/locales";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../components/context/CompanyContext";
import carrinho from "../../icons/carrinhos.svg";
import valor_medio from "../../icons/valor_medio.svg";
import { userRequest } from "../../requestMethods";
import { formatPricing, formatTimeDDMMYYY } from "../../util/helper";
import "./report.css";
import Avatar from "@mui/material/Avatar";
import { generateFileUrl } from "../../requestMethods";

export default function PreSalesReport() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [isLoading, setIsLoading] = useState(false);
  const [readonly, setReadonly] = useState();
  const [customers, setCustomers] = useState();
  const [products, setProducts] = useState();

  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!currentUser) {
      nav(`../login`);
    }

    if (companyId > 0) {
      getCustomers();
      getProducts();
    }
  }, [companyId]);

  const getCustomers = async () => {
    try {
      let url = "";
      setIsLoading(true);
      if (currentUser.isAdmin) {
        url = `${companyId}/customers/premium`;
      }

      const res = await userRequest.get(url);
      if (res.data) {
        setCustomers(res.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getProducts = async () => {
    try {
      setIsLoading(true);
      const res = await userRequest.get(`${companyId}/products/premium`);
      setProducts(res.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };
  const _voltarTopo = () => {
    window.scrollTo(0, 0);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );

  const columns = [
    {
      field: "customerId",
      headerName: "Cod. Cliente",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "owner_cpf",
      headerName: "CPF",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "cnpj_cpf",
      headerName: "CNPJ",
      width: 160,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "rzSocial",
      headerName: "Razão Social",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "contact",
      headerName: "Contato",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "managedBy",
      headerName: "Vendedor",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "onlineStore",
      headerName: "Inta ou Loja virtual",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "orderAmount",
      headerName: "Valor último pedido",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "orderDate",
      headerName: "Data último pedido",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "cartTotal",
      headerName: "Valor carrinho",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "cartDate",
      headerName: "Data carrinho",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "createdAt",
      headerName: "Criado",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "updatedAt",
      headerName: "Atualizado",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "acessAt",
      headerName: "Último Acesso",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
  ];
  const columnsProdutos = [
    {
      field: "avatar",
      headerName: "Foto",
      headerClassName: "dataGridMuiHeader",
      align: "center",
      headerAlign: "center",
      width: 50,
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <Avatar src={generateFileUrl(params.value)} />
          </>
        );
      },
    },
    {
      field: "enabled",
      headerName: "Visível",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "boolean",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (params.value) {
          return <VisibilityOutlinedIcon fontSize="small" color="success" />;
        } else {
          return (
            <VisibilityOffOutlinedIcon fontSize="small" sx={{ color: "red" }} />
          );
        }
      },
    },
    {
      field: "status",
      headerName: "Status Produto",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      description:
        "S = todas numeração tem em estoque e tem foto, P = alguma numeração não tem em estoque e tem foto, N = não tem estoque ou não tem foto",
      display: "flex",
      renderCell: (params) => {
        // eslint-disable-next-line default-case
        switch (params.value) {
          case "S":
            return (
              <>
                <CheckCircleOutlineOutlinedIcon color="success" />
              </>
            );
          case "N":
            return (
              <>
                <RemoveCircleOutlineOutlinedIcon sx={{ color: "red" }} />
              </>
            );
          case "P":
            return (
              <>
                <ReportGmailerrorredIcon sx={{ color: "#EE8253" }} />
              </>
            );
        }
      },
    },
    {
      field: "ref",
      headerName: "Referência",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "title",
      align: "left",
      headerName: "Produto",
      width: 250,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "category",
      headerName: "Categoria",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "price",
      headerName: "Preço",
      width: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "discount",
      headerName: "Preço Cheio",
      width: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "promo",
      headerName: "Promocional",
      width: 130,
      align: "center",
      headerAlign: "center",
      type: "boolean",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        return params.value ? <DiscountIcon color="success" /> : false;
      },
    },
    {
      field: "countImages",
      headerName: "Qtde de Fotos",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      type: "number",
    },
    {
      field: "stock",
      headerName: "Estoque",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      description:
        "S = todas numeração tem em estoque, P = alguma numeração não tem em estoque, N = não tem estoque",
      display: "flex",
      renderCell: (params) => {
        // eslint-disable-next-line default-case
        switch (params.value) {
          case "S":
            return (
              <>
                <CheckCircleOutlineOutlinedIcon color="success" />
              </>
            );
          case "N":
            return (
              <>
                <RemoveCircleOutlineOutlinedIcon sx={{ color: "red" }} />
              </>
            );
          case "P":
            return (
              <>
                <ReportGmailerrorredIcon sx={{ color: "#EE8253" }} />
              </>
            );
        }
      },
    },
    {
      field: "qtdeStock",
      headerName: "Qtde Produtod",
      width: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "createdAt",
      headerName: "Criada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "updatedAt",
      headerName: "Atualizada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "visibleAt",
      headerName: "Visível em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "updateImages",
      headerName: "Foto atualizada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "count",
      headerName: "Qtde nas vendas/acessados",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
  ];
  return (
    <div className="div_home_Container">
      <table width="100%" className="tableSpaceTab">
        <tbody>
          <tr>
            <div className="div_home_custom">
              <table className="table">
                <tbody>
                  <tr>
                    <td align="left" width="100%">
                      <tr>
                        <td>
                          <img src={cliente} />
                        </td>
                        <td>
                          <tr className="labelTitle">Clientes Premium</tr>
                          <tr className="labelMsgData">
                            Exibindo todos os dados
                          </tr>
                        </td>
                      </tr>
                    </td>
                    <td align="left" width="0%">
                      {" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ height: 350, width: "100%" }}>
                {customers ? (
                  <DataGridPremium
                    sx={{
                      fontFamily: "Manrope",
                      fontWeight: "500",
                      border: "0px",
                    }}
                    rowHeight={45}
                    localeText={
                      ptBR.components.MuiDataGrid.defaultProps.localeText
                    }
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{
                      panel: {
                        sx: {
                          top: "15px !important",
                          left: "50px !important",
                          position: "fixed !important",
                        },
                      },
                    }}
                    getRowId={(row) => row.customerId}
                    rows={customers}
                    disableSelectionOnClick
                    columns={columns}
                    pagination
                    autoPageSize
                  />
                ) : (
                  false
                )}
              </div>
            </div>
          </tr>
          <tr>
            <div className="div_home_custom">
              <table className="table">
                <tbody>
                  <tr>
                    <td align="left" width="100%">
                      <tr>
                        <td>
                          <img src={carrinho} />
                        </td>
                        <td>
                          <tr className="labelTitle">Produtos Pré-venda</tr>
                          <tr className="labelMsgData">
                            Exibindo todos os dados
                          </tr>
                        </td>
                      </tr>
                    </td>
                    <td align="left" width="0%">
                      {" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ height: 350, width: "100%" }}>
                {products ? (
                  <DataGridPremium
                    sx={{
                      fontFamily: "Manrope",
                      fontWeight: "500",
                      border: "0px",
                    }}
                    rowHeight={45}
                    localeText={
                      ptBR.components.MuiDataGrid.defaultProps.localeText
                    }
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{
                      panel: {
                        sx: {
                          top: "15px !important",
                          left: "50px !important",
                          position: "fixed !important",
                        },
                      },
                    }}
                    getRowId={(row) => row.productId}
                    rows={products}
                    columns={columnsProdutos}
                    pagination
                    autoPageSiz
                  />
                ) : (
                  false
                )}
              </div>
            </div>
          </tr>
        </tbody>
      </table>
      <div className="divRodape" onClick={_voltarTopo}>
        voltar ao topo
      </div>
    </div>
  );
}
