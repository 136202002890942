/* eslint-disable jsx-a11y/alt-text */
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DiscountIcon from "@mui/icons-material/Discount";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useCompany } from "../../components/context/CompanyContext";
import carrinho from "../../icons/carrinhos.svg";
import valor_medio from "../../icons/valor_medio.svg";
import { userRequest } from "../../requestMethods";
import { formatPricing, formatTimeDDMMYYY } from "../../util/helper";
import "./report.css";
import Avatar from "@mui/material/Avatar";
import { generateFileUrl } from "../../requestMethods";

export default function ProductReport() {
  const [prdNounSeller, setPrdNounSeller] = useState();
  const [prdBestSeller, setPrdBestSeller] = useState();
  const [products, setProducts] = useState();
  const [stock, setStock] = useState();
  const [withoutOrder, setwithoutOrder] = useState();
  const [gridStock, setGridStock] = useState();
  const [prdAccess, setPrdAccess] = useState();
  const [dataIni, setDateIni] = useState();
  const [dataFim, setDateFim] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [readonly, setReadonly] = useState();
  const [ordersQuantity, setOrdersQuantity] = useState();
  const [avgOrdersQuantity, setAvgOrdersQuantity] = useState();
  const [prdOver, setPrdOver] = useState();
  const nav = useNavigate();
  const [columns] = useState([
    {
      field: "avatar",
      headerName: "Foto",
      headerClassName: "dataGridMuiHeader",
      align: "center",
      headerAlign: "center",
      width: 50,
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <Avatar src={generateFileUrl(params.value)} />
          </>
        );
      },
    },
    {
      field: "product",
      headerName: "Referência",
      headerClassName: "dataGridMuiHeader",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "color",
      headerName: "Cor",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              height: "40px",
              width: "40px",
              backgroundColor: params.value,
              borderRadius: "5px",
            }}
          ></Box>
        );
      },
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setReadonly("7");
    if (!currentUser) {
      nav(`../login`);
    }

    if (companyId > 0) {
      getSizes();
      getOrdersbyQuantity();
      getAvgOrdersbyUser();
      getProductWithoutOrder();
      getProductsNounSeller();
      getProductsBestSeller();
      getStock();
      getGridStock();
      getProductAcess();
      getAllPrdOver();
    }
    setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
    setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (companyId > 0) {
      getOrdersbyQuantity();
      getAvgOrdersbyUser();
      getProductsNounSeller();
      getProductsBestSeller();
      getStock();
      getProductWithoutOrder();
      getProductAcess();
      getAllPrdOver();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIni, dataFim]);

  const getOrdersbyQuantity = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrders/userquantity`,
          obj
        );
        setOrdersQuantity(res.data);
      }
    } catch {}
  };
  const getProductWithoutOrder = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportProducts/withoutOrder`,
          obj
        );
        setwithoutOrder(res.data);
      }
    } catch {}
  };
  const addColumn = (newColumn) => {
    const colum = columns.find((item) => item.field === newColumn.field);

    if (!colum) {
      columns.push(newColumn);
    }
  };

  const getSizes = async () => {
    try {
      const res = await userRequest.get(`${companyId}/products/sizes`);
      res.data.map((item) =>
        addColumn({
          field: item.ref,
          headerName: item.label,
          headerClassName: "dataGridMuiHeader",
          width: 80,
          align: "left",
        })
      );
    } catch {}
  };

  const getAvgOrdersbyUser = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrders/avgordersusequantity`,
          obj
        );

        setAvgOrdersQuantity(res.data);
      }
    } catch {}
  };
  const getProductsBestSeller = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrders/productsBestSeller`,
          obj
        );
        setPrdBestSeller(res.data);
      }
    } catch {}
  };
  const getProductsNounSeller = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrders/productsNounSeller`,
          obj
        );
        setPrdNounSeller(res.data);
      }
    } catch {}
  };
  const getAnaliticoProductsBestAndNonSeller = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrders/productsBestAndNonSeller`,
          obj
        );
        setProducts(res.data);
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getProductsMaiorMenorRef = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/events/productMaiorMenorRef`,
          obj
        );
        setProducts(res.data);
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };

  const getProductAcess = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/events/productacess`,
          obj
        );
        setPrdAccess(res.data);
      }
    } catch {}
  };
  const getAllPrdOver = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/prdOver/allPrdOver`,
          obj
        );
        setPrdOver(res.data);
      }
    } catch {}
  };
  const getStock = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportProducts/prodstock`
      );

      setStock(res.data);
    } catch {}
  };

  // stockOver: stockOver ? stockOver.length : 0,
  // m50: m50 ? m50.length : 0,
  // comepleted: comepleted ? comepleted.length : 0,
  const getAnaliticoStockm25 = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportProducts/prodanaliticostock/m25`
      );
      setProducts(res.data);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };
  const getAnaliticoStockstockOver = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportProducts/prodanaliticostock/stockOver`
      );
      setProducts(res.data);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };
  const getAnaliticoStockm25e50 = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportProducts/prodanaliticostock/m25e50`
      );
      setProducts(res.data);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };
  const getAnaliticoStockm50 = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportProducts/prodanaliticostock/m50`
      );
      setProducts(res.data);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };

  const getAnaliticoStockcomepleted15 = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportProducts/prodanaliticostock/completed15`
      );
      setProducts(res.data);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };
  const getAnaliticoStockcomepleted15e30 = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportProducts/prodanaliticostock/completed15e30`
      );
      setProducts(res.data);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };
  const getAnaliticoStockcomepleted31e30 = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportProducts/prodanaliticostock/completed31e60`
      );
      setProducts(res.data);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };
  const getAnaliticoStockcomepleted60 = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportProducts/prodanaliticostock/completed60`
      );
      setProducts(res.data);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };

  const getAnaliticoStockcomepletedVisible = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportProducts/prodanaliticostock/completedVisible`
      );
      setProducts(res.data);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };
  const getAnaliticoStockincomepletedVisible = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportProducts/prodanaliticostock/incompletedVisible`
      );
      setProducts(res.data);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };
  const getGridStock = async () => {
    try {
      const res = await userRequest.get(`${companyId}/grid/stock`);

      setGridStock(res.data);
    } catch {}
  };

  const _voltarTopo = () => {
    window.scrollTo(0, 0);
  };
  const handleChangeStart = (e) => {
    setDateIni(e.target.value);
  };
  const handleChangeEnd = (e) => {
    setDateFim(e.target.value);
  };
  const handleChange = (event) => {
    setReadonly(event.target.value);
    // eslint-disable-next-line default-case
    switch (event.target.value) {
      case "0":
        setDateIni(dayjs(new Date()).format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "7":
        setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "15":
        setDateIni(dayjs(new Date()).add(-15, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "30":
        setDateIni(dayjs(new Date()).add(-30, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  const columnsSemVenda = [
    {
      field: "avatar",
      headerName: "Foto",
      headerClassName: "dataGridMuiHeader",
      align: "center",
      headerAlign: "center",
      width: 50,
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <Avatar src={generateFileUrl(params.value)} />
          </>
        );
      },
    },
    {
      field: "ref",
      headerName: "Referência",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "title",
      align: "left",
      headerName: "Produto",
      width: 250,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "price",
      headerName: "Preço",
      width: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "discount",
      headerName: "Preço Cheio",
      width: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "createdAt",
      headerName: "Criada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "updatedAt",
      headerName: "Atualizada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
  ];
  const columnsProduto = [
    {
      field: "avatar",
      headerName: "Foto",
      headerClassName: "dataGridMuiHeader",
      align: "center",
      headerAlign: "center",
      width: 50,
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <Avatar src={generateFileUrl(params.value)} />
          </>
        );
      },
    },
    {
      field: "enabled",
      headerName: "Visível",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "boolean",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (params.value) {
          return <VisibilityOutlinedIcon fontSize="small" color="success" />;
        } else {
          return (
            <VisibilityOffOutlinedIcon fontSize="small" sx={{ color: "red" }} />
          );
        }
      },
    },
    {
      field: "status",
      headerName: "Status Produto",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      description:
        "S = todas numeração tem em estoque e tem foto, P = alguma numeração não tem em estoque e tem foto, N = não tem estoque ou não tem foto",
      display: "flex",
      renderCell: (params) => {
        // eslint-disable-next-line default-case
        switch (params.value) {
          case "S":
            return (
              <>
                <CheckCircleOutlineOutlinedIcon color="success" />
              </>
            );
          case "N":
            return (
              <>
                <RemoveCircleOutlineOutlinedIcon sx={{ color: "red" }} />
              </>
            );
          case "P":
            return (
              <>
                <ReportGmailerrorredIcon sx={{ color: "#EE8253" }} />
              </>
            );
        }
      },
    },
    {
      field: "ref",
      headerName: "Referência",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "title",
      align: "left",
      headerName: "Produto",
      width: 250,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "category",
      headerName: "Categoria",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "price",
      headerName: "Preço",
      width: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "discount",
      headerName: "Preço Cheio",
      width: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "promo",
      headerName: "Promocional",
      width: 130,
      align: "center",
      headerAlign: "center",
      type: "boolean",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        return params.value ? <DiscountIcon color="success" /> : false;
      },
    },
    {
      field: "countImages",
      headerName: "Qtde de Fotos",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      type: "number",
    },
    {
      field: "stock",
      headerName: "Estoque",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      description:
        "S = todas numeração tem em estoque, P = alguma numeração não tem em estoque, N = não tem estoque",
      display: "flex",
      renderCell: (params) => {
        // eslint-disable-next-line default-case
        switch (params.value) {
          case "S":
            return (
              <>
                <CheckCircleOutlineOutlinedIcon color="success" />
              </>
            );
          case "N":
            return (
              <>
                <RemoveCircleOutlineOutlinedIcon sx={{ color: "red" }} />
              </>
            );
          case "P":
            return (
              <>
                <ReportGmailerrorredIcon sx={{ color: "#EE8253" }} />
              </>
            );
        }
      },
    },
    {
      field: "qtdeStock",
      headerName: "Qtde Produtod",
      width: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "createdAt",
      headerName: "Criada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "updatedAt",
      headerName: "Atualizada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "visibleAt",
      headerName: "Visível em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "updateImages",
      headerName: "Foto atualizada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "count",
      headerName: "Qtde nas vendas/acessados",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
  ];
  const columnsPrdOver = [
    {
      field: "avatar",
      headerName: "Foto",
      headerClassName: "dataGridMuiHeader",
      align: "center",
      headerAlign: "center",
      width: 50,
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <Avatar src={generateFileUrl(params.value)} />
          </>
        );
      },
    },
    {
      field: "ref",
      headerName: "Referência",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "title",
      align: "left",
      headerName: "Produto",
      width: 250,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "name",
      align: "left",
      headerName: "Nome",
      width: 250,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "contact",
      align: "left",
      headerName: "Contato",
      width: 150,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "createdAt",
      headerName: "Criada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
  ];
  return (
    <div className="div_home_Container">
      <table width="100%" className="tableSpaceTab">
        <tbody>
          <tr>
            <div className="div_home_custom">
              <table className="table">
                <tbody>
                  <tr>
                    <td align="left" width="100%">
                      <tr>
                        <td>
                          <img src={carrinho} />
                        </td>
                        <td>
                          <tr className="labelTitle">Grade completa</tr>
                          <tr className="labelMsgData">
                            Exibindo todos os dados
                          </tr>
                        </td>
                      </tr>
                    </td>
                    <td align="left" width="0%">
                      {" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table_painel">
                <tbody>
                  <tr>
                    <td width="20%" className="status_painel">
                      Até 15 dias
                    </td>
                    <td width="20%" className="status_painel">
                      De 16 dias até 30 dias
                    </td>
                    <td width="20%" className="status_painel">
                      De 31 dias até 60 dias
                    </td>
                    <td width="20%" className="status_painel">
                      Acima de 60 dias
                    </td>
                    <td width="20%" className="status_painel">
                      Invisível com estoque
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="20%"
                      className="labelValue_painel_black"
                      onClick={getAnaliticoStockcomepleted15}
                    >
                      {stock && stock.completed15 ? stock.completed15 : 0}
                    </td>
                    <td
                      width="20%"
                      className="labelValue_painel_black"
                      onClick={getAnaliticoStockcomepleted15e30}
                    >
                      {stock && stock.completed15e30 ? stock.completed15e30 : 0}
                    </td>
                    <td
                      width="20%"
                      className="labelValue_painel_black"
                      onClick={getAnaliticoStockcomepleted31e30}
                    >
                      {stock && stock.completed31e60 ? stock.completed31e60 : 0}
                    </td>
                    <td
                      width="20%"
                      className="labelValue_painel_black"
                      onClick={getAnaliticoStockcomepleted60}
                    >
                      {stock && stock.completed60 ? stock.completed60 : 0}
                    </td>
                    <td
                      width="20%"
                      className="labelValue_painel_black"
                      onClick={getAnaliticoStockcomepletedVisible}
                    >
                      {stock && stock.completedVisible
                        ? stock.completedVisible
                        : 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </tr>

          <tr>
            <div className="div_home_custom">
              <table className="table">
                <tbody>
                  <tr>
                    <td align="left" width="100%">
                      <tr>
                        <td>
                          <img src={carrinho} />
                        </td>
                        <td>
                          <tr className="labelTitle">Grade incompleta</tr>
                          <tr className="labelMsgData">
                            Exibindo todos os dados
                          </tr>
                        </td>
                      </tr>
                    </td>
                    <td align="left" width="0%">
                      {" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table_painel">
                <tbody>
                  <tr>
                    <td width="20%" className="status_painel">
                      Menor que 25pcs
                    </td>
                    <td width="20%" className="status_painel">
                      Entre 25pc a 50pcs
                    </td>
                    <td width="20%" className="status_painel">
                      Maior que 50pcs
                    </td>
                    <td width="20%" className="status_painel">
                      Visível sem estoque
                    </td>
                    <td width="20%" className="status_painel">
                      Invisível com estoque
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="20%"
                      className="labelValue_painel_black"
                      onClick={getAnaliticoStockm25}
                    >
                      {stock && stock.m25 ? stock.m25 : 0}
                    </td>
                    <td
                      width="20%"
                      className="labelValue_painel_black"
                      onClick={getAnaliticoStockm25e50}
                    >
                      {stock && stock.m25e50 ? stock.m25e50 : 0}
                    </td>
                    <td
                      width="20%"
                      className="labelValue_painel_black"
                      onClick={getAnaliticoStockm50}
                    >
                      {stock && stock.m50 ? stock.m50 : 0}
                    </td>
                    <td
                      width="20%"
                      className="labelValue_painel_black"
                      onClick={getAnaliticoStockstockOver}
                    >
                      {stock && stock.stockOver ? stock.stockOver : 0}
                    </td>
                    <td
                      width="20%"
                      className="labelValue_painel_black"
                      onClick={getAnaliticoStockincomepletedVisible}
                    >
                      {stock && stock.incompletedVisible
                        ? stock.incompletedVisible
                        : 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </tr>
          <tr>
            <div className="div_home_custom">
              <table className="table">
                <tbody>
                  <tr>
                    <td align="left" width="100%">
                      <tr>
                        <td>
                          <img src={carrinho} />
                        </td>
                        <td>
                          <tr className="labelTitle">Grade Incompleta</tr>
                          <tr className="labelMsgData">
                            Exibindo todos os dados
                          </tr>
                        </td>
                      </tr>
                    </td>
                    <td align="left" width="0%">
                      {" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ height: 350, width: "100%" }}>
                {gridStock ? (
                  <DataGridPremium
                    sx={{
                      fontFamily: "Manrope",
                      fontWeight: "500",
                      border: "0px",
                    }}
                    rowHeight={45}
                    localeText={
                      ptBRCore.components.MuiDataGrid.defaultProps.localeText
                    }
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{
                      panel: {
                        sx: {
                          top: "15px !important",
                          left: "50px !important",
                          position: "fixed !important",
                        },
                      },
                    }}
                    getRowId={(row) =>
                      row.color + row.size + row.stock + row.product
                    }
                    rows={gridStock}
                    disableSelectionOnClick
                    columns={columns}
                    pageSize={8}
                  />
                ) : (
                  false
                )}
              </div>
            </div>
          </tr>
        </tbody>
      </table>
      <div>
        <div className="datePicker">
          <label className="labelPeriodo">
            Filtrar:
            <input
              type="date"
              id="startDate"
              value={dataIni}
              name="trip-start"
              min="2023-01-01"
              onChange={handleChangeStart}
              disabled={readonly !== "F"}
            />
            <input
              type="date"
              id="endDate"
              value={dataFim}
              name="trip-start"
              min="2023-01-01"
              onChange={handleChangeEnd}
              disabled={readonly !== "F"}
            />
          </label>
        </div>
        <div className="column">
          <input
            type="radio"
            id="30"
            value="30"
            onChange={handleChange}
            checked={readonly === "30"}
          />
          <label for="30">30 dias</label>
          <input
            type="radio"
            id="15"
            value="15"
            onChange={handleChange}
            checked={readonly === "15"}
          />
          <label for="15">15 dias</label>
          <input
            type="radio"
            id="7"
            value="7"
            onChange={handleChange}
            checked={readonly === "7"}
          />
          <label for="7">7 dias</label>
          <input
            type="radio"
            id="0"
            value="0"
            onChange={handleChange}
            checked={readonly === "0"}
          />
          <label for="0">Hoje</label>
          <input
            type="radio"
            id="0"
            value="F"
            onChange={handleChange}
            checked={readonly === "F"}
          />
          <label for="0">Filtro</label>
        </div>
      </div>
      <table width="100%" className="tableSpaceTab">
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tr>
                <td align="left" width="100%">
                  <tr>
                    <td>
                      <img src={carrinho} />
                    </td>
                    <td>
                      <tr className="labelTitle">Produtos</tr>
                      <tr className="labelMsgData">
                        {readonly === "F"
                          ? "Exibindo dados do filtro de data"
                          : readonly === "0"
                          ? "Exibindo dados de hoje"
                          : "Exibindo dados dos últimos " + readonly + " dias"}
                      </tr>
                    </td>
                  </tr>
                </td>
                <td align="left" width="0%">
                  {" "}
                </td>
              </tr>
            </table>
            <table className="table_painel">
              <tr>
                <td width="25%" className="status_painel">
                  Mais vendido(
                  {prdBestSeller && prdBestSeller.count
                    ? prdBestSeller.count
                    : 0}
                  )
                </td>
                <td width="25%" className="status_painel">
                  Menos vendido(
                  {prdNounSeller && prdNounSeller.count
                    ? prdNounSeller.count
                    : 0}
                  )
                </td>
                <td width="25%" className="status_painel">
                  Mais acessado(
                  {prdAccess && prdAccess.maiorRef
                    ? prdAccess.maiorRef.count
                    : 0}
                  )
                </td>
                <td width="25%" className="status_painel">
                  Menos acessado(
                  {prdAccess && prdAccess.maiorRef
                    ? prdAccess.maiorRef.count
                    : 0}
                  )
                </td>
              </tr>
              <tr>
                <td
                  width="25%"
                  className="labelValue_painel_black"
                  onClick={getAnaliticoProductsBestAndNonSeller}
                >
                  {prdBestSeller && prdBestSeller.ref ? prdBestSeller.ref : 0}
                </td>
                <td
                  width="25%"
                  className="labelValue_painel_black"
                  onClick={getAnaliticoProductsBestAndNonSeller}
                >
                  {prdNounSeller && prdNounSeller.ref ? prdNounSeller.ref : 0}
                </td>
                <td
                  width="25%"
                  className="labelValue_painel_black"
                  onClick={getProductsMaiorMenorRef}
                >
                  {" "}
                  {prdAccess && prdAccess.maiorRef ? prdAccess.maiorRef.ref : 0}
                </td>
                <td
                  width="25%"
                  className="labelValue_painel_black"
                  onClick={getProductsMaiorMenorRef}
                >
                  {" "}
                  {prdAccess && prdAccess.menorRef ? prdAccess.menorRef.ref : 0}
                </td>
              </tr>
            </table>
          </div>
        </tr>
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tr>
                <td align="left" width="100%">
                  <tr>
                    <td>
                      <img src={carrinho} />
                    </td>
                    <td>
                      <tr className="labelTitle">Produtos sem venda</tr>
                      <tr className="labelMsgData">
                        {readonly === "F"
                          ? "Exibindo dados do filtro de data"
                          : readonly === "0"
                          ? "Exibindo dados de hoje"
                          : "Exibindo dados dos últimos " + readonly + " dias"}
                      </tr>
                    </td>
                  </tr>
                </td>
                <td align="left" width="0%">
                  {" "}
                </td>
              </tr>
            </table>
            <div style={{ height: 350, width: "100%" }}>
              {withoutOrder ? (
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBRCore.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.productId}
                  rows={withoutOrder}
                  disableSelectionOnClick
                  columns={columnsSemVenda}
                  pageSize={8}
                />
              ) : (
                false
              )}
            </div>
          </div>
        </tr>
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tr>
                <td align="left" width="100%">
                  <tr>
                    <td>
                      <img src={carrinho} />
                    </td>
                    <td>
                      <tr className="labelTitle">Avisar quando chegar</tr>
                      <tr className="labelMsgData">
                        {readonly === "F"
                          ? "Exibindo dados do filtro de data"
                          : readonly === "0"
                          ? "Exibindo dados de hoje"
                          : "Exibindo dados dos últimos " + readonly + " dias"}
                      </tr>
                    </td>
                  </tr>
                </td>
                <td align="left" width="0%">
                  {" "}
                </td>
              </tr>
            </table>
            <div style={{ height: 350, width: "100%" }}>
              {prdOver && prdOver.lenght > 0 ? (
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBRCore.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.productOverId + row.ref}
                  rows={prdOver}
                  disableSelectionOnClick
                  columns={columnsPrdOver}
                  pageSize={8}
                />
              ) : (
                false
              )}
            </div>
          </div>
        </tr>
      </table>
      <table width="100%" className="tableSpaceTab2">
        <tr>
          <td width="50%">
            <div className="div_home_custom">
              <table className="table">
                <tr>
                  <td align="left" width="100%">
                    <tr>
                      <td>
                        <img src={valor_medio} />
                      </td>
                      <td>
                        <tr className="labelTitle">
                          Quantidade por vendedor(a)
                        </tr>
                        <tr className="labelMsgData">
                          {readonly === "F"
                            ? "Exibindo dados do filtro de data"
                            : readonly === "0"
                            ? "Exibindo dados de hoje"
                            : "Exibindo dados dos últimos " +
                              readonly +
                              " dias"}
                        </tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left" width="0%">
                    {" "}
                  </td>
                </tr>
              </table>
              <br></br>
              <ResponsiveContainer width="95%" height={250}>
                <BarChart data={ordersQuantity}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    // textAnchor= "end" sclaeToFit="true" verticalAnchor= "start"
                    interval={0}
                    angle={-40}
                    type="category"
                    dataKey="managedBy"
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis type="number" tick={{ fontSize: 12 }} />
                  <Tooltip />
                  <Bar dataKey="Quantidade" fill="#EE8253" barSize={5} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </td>
          <td>
            <div className="div_home_custom">
              <table className="table">
                <tr>
                  <td align="left" width="100%">
                    <tr>
                      <td>
                        <img src={valor_medio} />
                      </td>
                      <td>
                        <tr className="labelTitle">
                          Quantidade média por vendedor(a)
                        </tr>
                        <tr className="labelMsgData">
                          {readonly === "F"
                            ? "Exibindo dados do filtro de data"
                            : readonly === "0"
                            ? "Exibindo dados de hoje"
                            : "Exibindo dados dos últimos " +
                              readonly +
                              " dias"}
                        </tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left" width="0%">
                    {" "}
                  </td>
                </tr>
              </table>
              <br></br>
              <ResponsiveContainer width="95%" height={250}>
                <BarChart data={avgOrdersQuantity}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    interval={0}
                    angle={-40}
                    type="category"
                    dataKey="managedBy"
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis type="number" tick={{ fontSize: 12 }} />
                  <Tooltip />
                  <Bar dataKey="Quantidade" fill="#EE8253" barSize={5} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </td>
        </tr>
      </table>
      <table width="100%" className="tableSpaceTab">
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tr>
                <td align="left" width="100%">
                  <tr>
                    <td>
                      <img src={carrinho} />
                    </td>
                    <td>
                      <tr className="labelTitle">Analítico grade</tr>
                      <tr className="labelMsgData">Exibindo todos os dados</tr>
                    </td>
                  </tr>
                </td>
                <td align="left" width="0%">
                  {" "}
                </td>
              </tr>
            </table>
            <div style={{ height: 350, width: "100%" }}>
              {products ? (
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBRCore.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.productId}
                  rows={products}
                  columns={columnsProduto}
                  pageSize={8}
                />
              ) : (
                false
              )}
            </div>
          </div>
        </tr>
      </table>
      <div className="divRodape" onClick={_voltarTopo}>
        voltar ao topo
      </div>
    </div>
  );
}
