import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../components/context/CompanyContext";
import FeaturedInfo from "../../components/customer_dash/FeaturedInfo";
import Loading from "../../components/loading/loading";
import { userRequest } from "../../requestMethods";
import { formatTime24hourFormat, formatTimeDDMMYYY } from "../../util/helper";
import "./customer_dash.css";

export default function Customer_Dash() {
  const [orders, setOrders] = useState([]);
  const [acess, setAcess] = useState([]);
  const [dataIni, setDateIni] = useState();
  const [dataFim, setDateFim] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [isLoading, setIsLoading] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      nav(`../login`);
    }
    if (companyId > 0) {
      getOrdersbyDay();
      getCustomerNotOrder();
      getCustomerNotAcess();
    }
    setDateIni(dayjs(new Date()).format("YYYY-MM-DD"));
    setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    setIsLoading(true);
    if (companyId > 0) {
      //getOrdersbyDay();
      getCustomerNotOrder();
      getCustomerNotAcess();
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIni, dataFim]);

  const getOrdersbyDay = async () => {
    let obj = {
      start: dataIni,
      end: dataFim,
    };

    if (!currentUser.isAdmin) {
      obj = {
        start: dataIni,
        end: dataFim,
        userId: currentUser.userId,
      };
    }
    const res = await userRequest.post(`${companyId}/orders/groupbyday`, obj);

    setOrders(
      res.data.map((order) => ({
        criado: formatTimeDDMMYYY(order.createdAt),
        total: order.amount,
      }))
    );
  };
  const getCustomerNotOrder = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (!currentUser.isAdmin) {
        obj = {
          start: dataIni,
          end: dataFim,
          userId: currentUser.userId,
        };
      }
      const res = await userRequest.post(`${companyId}/events/notorder`, obj);
      setOrders(res.data);
    } catch (err) {
      console.error(err);
    }
  };
  const getCustomerNotAcess = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (!currentUser.isAdmin) {
        obj = {
          start: dataIni,
          end: dataFim,
          userId: currentUser.userId,
        };
      }

      const res = await userRequest.post(`${companyId}/events/notacess`, obj);
      setAcess(res.data);
    } catch (err) {
      console.error(err);
    }
  };
  const handleChangeStart = (e) => {
    setDateIni(e.target.value);
  };
  const handleChangeEnd = (e) => {
    setDateFim(e.target.value);
  };

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      width: 250,
      align: "left",
      headerAlign: "center",
    },
    {
      field: "contact",
      headerName: "Contato",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "updatedAt",
      headerName: "Última Acesso",
      width: 200,
      align: "left",
      valueFormatter: (params) => formatTime24hourFormat(params?.value),
    },
  ];
  const columnsAcess = [
    {
      field: "name",
      headerName: "Nome",
      width: 250,
      align: "left",
      headerAlign: "center",
    },
    {
      field: "contact",
      headerName: "Contato",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "createdAt",
      headerName: "Criado",
      width: 200,
      align: "left",
      valueFormatter: (params) => formatTime24hourFormat(params?.value),
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <div className="dashCustomer">
      <div className="datePicker">
        <label>
          Período:
          <input
            type="date"
            id="startDate"
            value={dataIni}
            name="trip-start"
            min="2023-01-01"
            onChange={handleChangeStart}
          />
          <input
            type="date"
            id="endDate"
            value={dataFim}
            name="trip-start"
            min="2023-01-01"
            onChange={handleChangeEnd}
          />
        </label>
      </div>
      <div>
        <FeaturedInfo dataIni={dataIni} dataFim={dataFim} />
      </div>
      {/*<div>
    <RejectionsPie  dataIni={dataIni} dataFim={dataFim}/>
  </div>*/}

      <div className="list">
        <label>Clientes que acessam mas não compram</label>
        {isLoading ? (
          <Loading />
        ) : (
          <DataGridPremium
            initialState={{
              sorting: {
                sortModel: [{ field: "count", sort: "desc" }],
              },
            }}
            autoHeight
            rowHeight={25}
            localeText={ptBRCore.components.MuiDataGrid.defaultProps.localeText}
            slots={{ toolbar: CustomToolbar }}
            slotProps={{
              panel: {
                sx: {
                  top: "15px !important",
                  left: "50px !important",
                  position: "fixed !important",
                },
              },
            }}
            getRowId={(row) => row.customerId + row.updatedAt}
            rows={orders}
            disableSelectionOnClick
            columns={columns}
            pageSize={8}
          />
        )}
      </div>
      <div className="list">
        <label>Clientes cadastrados mas não acessam</label>
        {isLoading ? (
          <Loading />
        ) : (
          <DataGridPremium
            initialState={{
              sorting: {
                sortModel: [{ field: "count", sort: "desc" }],
              },
            }}
            autoHeight
            rowHeight={25}
            localeText={ptBRCore.components.MuiDataGrid.defaultProps.localeText}
            slots={{ toolbar: CustomToolbar }}
            slotProps={{
              panel: {
                sx: {
                  top: "15px !important",
                  left: "50px !important",
                  position: "fixed !important",
                },
              },
            }}
            getRowId={(row) => row.customerId + row.createdAt}
            rows={acess}
            disableSelectionOnClick
            columns={columnsAcess}
            pageSize={8}
          />
        )}
      </div>
    </div>
  );
}
