import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import * as React from "react";
import { useEffect, useState } from "react";
import { useCompany } from "../../components/context/CompanyContext";
import Loading from "../../components/loading/loading";
import "../../global.css";
import { userRequest } from "../../requestMethods";
import { formatTime24hourFormat } from "../../util/helper";

export default function LeadList() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rejections, setRejections] = useState([]);
  const { companyId } = useCompany();

  useEffect(() => {
    setIsLoading(true);
    if (companyId > 0) {
      getLeads();
      getReason();
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {}, [data]);
  const getReason = async () => {
    const res = await userRequest.get(`${companyId}/rejections`);

    setRejections(res.data);
  };
  const getLeads = async () => {
    const res = await userRequest.get(`${companyId}/leads`);

    setData(
      res.data.map((item) => ({
        lead: item.lead,
        count: item.count,
        createdAt: item.createdAt,
        reason: item.leadRejection ? item.leadRejection.rejection : "",
      }))
    );
  };

  const handleChange = (e) => {
    let lead = e.target.value.split("-");

    updateReason(lead[0], lead[1]);
  };

  const updateReason = async (rejectionId, lead) => {
    // const res = await userRequest.put(`${companyId}/leads/`,{rejectionId,lead});
  };

  const columns = [
    {
      field: "lead",
      headerName: "Lead",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "count",
      headerName: "Tentativas de cadastro",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "createdAt",
      headerName: "Última Tentativa",
      width: 200,
      align: "left",
      valueFormatter: (params) => formatTime24hourFormat(params?.value),
      type: "date",
    },
    {
      field: "reason",
      headerName: "Motivo",
      width: 200,
      align: "center",
      headerAlign: "center",
      display: "flex",
      renderCell: (params) => {
        if (params.row.reason === "") {
          return (
            <>
              <select
                className="noBorder"
                name="reason"
                onChange={handleChange}
                value={data ? data.reason : ""}
              >
                <option value=""></option>
                {rejections
                  ? rejections.map((item) => (
                      <option value={item.rejectionId + "-" + params.row.lead}>
                        {item.rejection}
                      </option>
                    ))
                  : false}
              </select>
            </>
          );
        } else {
          return params.row.reason;
        }
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <div className="list">
      {isLoading ? (
        <Loading />
      ) : (
        <DataGridPremium
          initialState={{
            sorting: {
              sortModel: [{ field: "count", sort: "desc" }],
            },
          }}
          autoHeight
          rowHeight={25}
          localeText={ptBRCore.components.MuiDataGrid.defaultProps.localeText}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            panel: {
              sx: {
                top: "15px !important",
                left: "50px !important",
                position: "fixed !important",
              },
            },
          }}
          getRowId={(row) => row.lead}
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={8}
        />
      )}
    </div>
  );
}
