import { createSlice } from "@reduxjs/toolkit";

const urlSlice = createSlice({
  name: "url",
  initialState: {
    currentSlug: null,
    isFetching: false,
    error: null,
  },
  reducers: {
    urlSetRef: (state, action) => {
      state.isFetching = false;
      state.currentSlug = action.payload;
      state.error = false;
    },
    urlSetRefEnd: (state) => {
      state.currentSlug = null;
      state.isFetching = false;
      state.error = null;
    },
  },
});

export const { urlSetRef, urlSetRefEnd } = urlSlice.actions;
export default urlSlice.reducer;
