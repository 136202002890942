import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import SidebarComponent from "./components/SidebarComponent";
import Topbar from "./components/Topbar";
import Campaign from "./pages/campaign/campaign";
import CampaignList from "./pages/campaign/campaignList";
import CategoryList from "./pages/categories/categoryList";
import ImportCategories from "./pages/categories/importCategory";
import Company from "./pages/company/company";
import ColorsSizes from "./pages/config/color_sizes";
import Paleta from "./pages/config/paleta";
import Customer from "./pages/customer/customer";
import CustomerList from "./pages/customer/customerList";
import ImportCustomer from "./pages/customer/importCustomer";
import DashCustomers from "./pages/dashboard/customer_dash";
import Highlights from "./pages/highlights/highlights";
import Login from "./pages/login/login";
import Onboarding from "./pages/onboarding/onboarding";
import Itens from "./pages/order/itens";
import OrderList from "./pages/order/orderList";
import ImportProducts from "./pages/product/importProducts";
import ImportStock from "./pages/product/importStock";
import Product from "./pages/product/product";
import ProductList from "./pages/product/productList";
import CartListItem from "./pages/reports/cartListItem";
import Home from "./pages/reports/home";
import LeadList from "./pages/reports/leadList";
import TabConfig from "./pages/tabs/TabConfig";
import TabDashboard from "./pages/tabs/TabDashboard";
import TabImport from "./pages/tabs/TabImport";
import User from "./pages/user/user";
import UserList from "./pages/user/userList";
import { useCompany } from "../src/components/context/CompanyContext";
import DiscountList from "./pages/discount/discountList";
import Collection from "./pages/collection/collection";
import PaymentMoodaReport from "./pages/reports/paymentMoodaReport";
import Invoice from "./pages/invoice/invoice";

const Layout = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const currentSlug = useSelector((state) => state.urlRef.currentSlug);

  const { companyId, slug } = useCompany();
  useEffect(() => {
    if (currentUser) {
      const [_ignoreFirstSlash, rootParam] =
        window.location.pathname.split("/");

      if (
        rootParam !== "home" &&
        rootParam !== (currentSlug && currentSlug.slug)
      ) {
        var tela = "/login";
        localStorage.clear();

        var split = window.location.pathname.toString().split("/");
        var newUrl = window.location.origin + "/" + split[1] + tela;
        window.location.href = newUrl;
      }
    }
  }, [currentUser, companyId]);

  return (
    <div class="wrapper">
      {currentUser && Object.keys(currentUser).length > 0 ? (
        <header>
          <Topbar />
        </header>
      ) : (
        false
      )}
      <article>
        <Outlet />
      </article>
      {currentUser && Object.keys(currentUser).length > 0 ? (
        <aside>
          <SidebarComponent />
        </aside>
      ) : (
        false
      )}
    </div>
  );
};

const router = createBrowserRouter([
  // {
  //   path: "/onboarding",
  //   element: <Onboarding />,
  // },
  {
    path: "/:companyId/login",
    element: <Login />,
  },
  {
    path: "/:companyId",
    element: <Layout />,
    children: [
      {
        path: "/:companyId/home",
        element: <Home />,
      },
      {
        path: "/:companyId/users",
        element: <UserList />,
      },
      {
        path: "/:companyId/users/:userId",
        element: <User />,
      },
      {
        path: "/:companyId/customers",
        element: <CustomerList />,
      },
      {
        path: "/:companyId/customers/:customerId",
        element: <Customer />,
      },
      {
        path: "/:companyId/products",
        element: <ProductList />,
      },
      {
        path: "/:companyId/products/:productId",
        element: <Product />,
      },
      {
        path: "/:companyId/orders",
        element: <OrderList />,
      },
      {
        path: "/:companyId/orders/:orderId",
        element: <Itens />,
      },
      {
        path: "/:companyId/highlights",
        element: <Highlights />,
      },
      {
        path: "/:companyId/importproducts",
        element: <ImportProducts />,
      },
      {
        path: "/:companyId/paleta",
        element: <Paleta />,
      },
      {
        path: "/:companyId/colorsize",
        element: <ColorsSizes />,
      },
      {
        path: "/:companyId/importcategories",
        element: <ImportCategories />,
      },
      {
        path: "/:companyId/company",
        element: <Company />,
      },
      {
        path: "/:companyId/importcustomers",
        element: <ImportCustomer />,
      },
      {
        path: "/:companyId/importstock",
        element: <ImportStock />,
      },
      {
        path: "/:companyId/campaign",
        element: <CampaignList />,
      },
      {
        path: "/:companyId/campaign/:campaignId",
        element: <Campaign />,
      },
      {
        path: "/:companyId/carts",
        element: <CartListItem />,
      },
      {
        path: "/:companyId/leads",
        element: <LeadList />,
      },
      {
        path: "/:companyId/dashCustomers",
        element: <DashCustomers />,
      },
      {
        path: "/:companyId/categories",
        element: <CategoryList />,
      },
      {
        path: "/:companyId/tabimports",
        element: <TabImport />,
      },
      {
        path: "/:companyId/tabconfigs",
        element: <TabConfig />,
      },
      {
        path: "/:companyId/tabdashboard",
        element: <TabDashboard />,
      },
      {
        path: "/:companyId/discounts",
        element: <DiscountList />,
      },
      {
        path: "/:companyId/collection",
        element: <Collection />,
      },
      {
        path: "/:companyId/paymentReport",
        element: <PaymentMoodaReport />,
      },
      {
        path: "/:companyId/invoice",
        element: <Invoice />,
      },
    ],
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
