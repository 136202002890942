import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useCompany } from "../../components/context/CompanyContext";
import Loading from "../../components/loading/loading";
import "../../global.css";
import { userRequest } from "../../requestMethods";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Modal from "@mui/material/Modal";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import Container from "@mui/material/Container";
const style = {
  background: "white",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  padding: "60px",
  borderRadius: "15px",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

export default function User() {
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const [user, setUser] = useState({});
  const [inputs, setInputs] = useState({});
  const [cargo, setCargo] = useState("");
  const [newManaged, setNewManaged] = useState();
  const [admin, setAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [existUsername, setExistUsername] = useState();
  const { companyId } = useCompany();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState();
  const [stories, setStories] = useState([]);
  const [store, setStore] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const nav = useNavigate();

  useEffect(() => {
    if (id !== "new" && companyId > 0) {
      getStories();
      getUser();
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, companyId]);

  useEffect(() => {
    if (user) {
      if (user.position) {
        setCargo(user.position);
      }
      if (user.isAdmin) setAdmin(true);
      if (user.storeId) {
        setStore(user.storeId);
      }
    }
  }, [user]);

  const getUser = async () => {
    try {
      const res = await userRequest.get(`${companyId}/users/find/${id}`);

      // eslint-disable-next-line array-callback-return
      res.data.map((item) => {
        setUser(item);
      });
    } catch {
      console.log();
    }
  };
  const getUsername = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/users/username/${inputs.username}`
      );

      if (Object.keys(res.data).length > 0) {
        setExistUsername(true);
      } else {
        setExistUsername(false);
      }
    } catch {
      console.log();
    }
  };

  const validate = () => {
    if (inputs.username === "" || inputs.username === undefined) {
      Swal.fire("Atenção!", "O campo Username deve ser preenchido.", "warning");
      return false;
    }

    if (existUsername) {
      Swal.fire("Atenção!", "Username existente.", "warning");
      return false;
    }
    if (inputs.name === "" || inputs.name === undefined) {
      Swal.fire("Atenção!", "O campo Nome deve ser preenchido.", "warning");

      return false;
    }
    if (inputs.cpf === "" || inputs.cpf === undefined) {
      Swal.fire("Atenção!", "O campo CPF deve ser preenchido.", "warning");

      return false;
    }
    if (inputs.email === "" || inputs.email === undefined) {
      Swal.fire("Atenção!", "O campo Email deve ser preenchido.", "warning");

      return false;
    }

    if (inputs.contact === "" || inputs.contact === undefined) {
      Swal.fire("Atenção!", "O campo Telefone deve ser preenchido.", "warning");

      return false;
    }
    if (inputs.address === "" || inputs.address === undefined) {
      Swal.fire("Atenção!", "O campo Endereço deve ser preenchido.", "warning");

      return false;
    }
    if (!cargo) {
      Swal.fire("Atenção!", "O campo Cargo deve ser selecionado.", "warning");

      return false;
    }

    return true;
  };
  const updateClick = async (event) => {
    setIsLoading(true);
    if (id !== "new") {
      UpdateUser();
    } else {
      if (validate()) {
        InserirUser();
      }
    }
    setIsLoading(false);
  };
  const UpdateUser = async (event) => {
    try {
      const res = await userRequest.put(`${companyId}/users/${id}`, {
        userId: currentUser.userId,
        name: inputs.name,
        email: inputs.email,
        contact: inputs.contact,
        position: cargo,
        username: inputs.username,
        password: inputs.password,
        cpf: inputs.cpf,
        isAdmin: admin,
        address: inputs.address,
        lastUserUpdate: currentUser.userId,
        storeId: store,
      });

      if (Object.keys(res.data).length > 0) {
        Swal.fire("Parabéns!", "Usuário alterado com sucesso!", "success");
        nav(`../users`);
      } else {
        Swal.fire("Atenção!", "Erro ao alterar usuário!", "error");
      }
    } catch (error) {}
  };
  const InserirUser = async () => {
    try {
      const res = await userRequest.post(
        `${companyId}/users/registerEmployee`,
        [
          {
            name: inputs.name,
            email: inputs.email,
            contact: inputs.contact,
            position: cargo,
            username: inputs.username,
            password: inputs.password,
            cpf: inputs.cpf,
            isAdmin: admin,
            address: inputs.address,
            lastUserUpdate: currentUser.userId,
            storeId: store,
          },
        ]
      );

      if (Object.keys(res.data).length > 0) {
        Swal.fire("Parabéns!", "Usuário inserido com sucesso!", "success");
        nav(`../users`);
      } else {
        Swal.fire("Atenção!", "Erro ao inserir usuário!", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUsers = async () => {
    try {
      let url = `${companyId}/users`;

      const res = await userRequest.get(url);

      setUsers(res.data);
    } catch (error) {
      console.log(error.code);
    }
  };
  const getStories = async () => {
    try {
      let url = `${companyId}/store`;

      const res = await userRequest.get(url);

      setStories(res.data);
    } catch (error) {
      console.log(error.code);
    }
  };
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleBlur = (e) => {
    getUsername();
  };
  const handleChangePosition = (e) => {
    setCargo(e.target.value);
  };
  const handleChangeNewManaged = (e) => {
    setNewManaged(e.target.value);
  };
  const handleChangeAdmin = (e) => {
    //setAdmin(e.target.value);
    setAdmin(e.target.checked);
  };
  const transfUser = async (event) => {
    try {
      const res = await userRequest.put(`${companyId}/users/${id}`, {
        lastUserUpdate: currentUser.userId,
      });
      const resC = await userRequest.put(
        `${companyId}/customers/transfer/${id}`,
        { managedBy: newManaged }
      );
      if (Object.keys(res.data).length > 0) {
        Swal.fire("Parabéns!", "Clientes transferidos com sucesso!", "success");
        nav(`../users`);
      } else {
        Swal.fire("Atenção!", "Erro ao alterar usuário!", "error");
      }
    } catch (error) {}
  };
  const handleChangeStore = (e) => {
    setStore(e.target.value);
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <table className="table">
            <tr>
              <td align="left" width="100%">
                <tr>
                  <td>
                    <TransferWithinAStationIcon />
                  </td>
                  <td>
                    <tr className="labelTitle">Tranferência de clientes</tr>
                  </td>
                </tr>
              </td>
              <td align="left" width="0%">
                {" "}
              </td>
            </tr>
          </table>
          <table
            cellspacing="1"
            cellpadding="2"
            align="center"
            className="table"
          >
            <tr>
              <td align="left" className="left">
                De:
              </td>
              <td align="left" className="right_black_List">
                {id !== "new" && user ? user.username || "" : ""}
              </td>
            </tr>
            <tr>
              <td align="left" className="left">
                Para:
              </td>
              <td align="left" className="right_black_List">
                <select
                  className="div_custom_Select"
                  name="newManaged"
                  id="newManaged"
                  onChange={handleChangeNewManaged}
                  value={newManaged}
                >
                  <option value=""></option>
                  {users
                    ? users.map((item) => (
                        <option value={item.userId}>{item.name}</option>
                      ))
                    : false}
                </select>
              </td>
            </tr>
          </table>
          <div>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<SaveIcon />}
              onClick={transfUser}
            >
              Tranferir
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className="custom_add_back"
              onClick={() => nav(-1)}
            >
              Voltar
            </Button>
          </div>
        </div>
      </Modal>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Form">
          {id !== "new" && user ? (
            <span className="div_custom_UpdateTitle">Editar Usuário</span>
          ) : (
            <span className="div_custom_UpdateTitle">Novo Usuário</span>
          )}
          <div className="div_custom_UpdateLeft">
            <div className="div_custom_UpdateItem">
              <label>Username</label>
              <input
                type="text"
                className="div_custom_UpdateInput"
                name="username"
                defaultValue={id !== "new" && user ? user.username || "" : ""}
                onChange={handleChange}
                maxLength="20"
                onBlur={handleBlur}
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Senha</label>
              <input
                type="text"
                name="password"
                defaultValue={id !== "new" && user ? "" || "" : ""}
                className="div_custom_UpdateInput"
                onChange={handleChange}
                maxLength="20"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Nome</label>
              <input
                type="text"
                name="name"
                defaultValue={id !== "new" && user ? user.name || "" : ""}
                className="div_custom_UpdateInput"
                onChange={handleChange}
                maxLength="200"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>CPF</label>
              <input
                type="number"
                name="cpf"
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" && user ? user.cpf || "" : ""}
                onChange={handleChange}
                maxLength="11"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Email</label>
              <input
                type="email"
                name="email"
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" && user ? user.email || "" : ""}
                onChange={handleChange}
                maxLength="200"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Telefone</label>
              <input
                type="number"
                name="contact"
                onChange={handleChange}
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" && user ? user.contact || "" : ""}
                maxLength="12"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Endereço</label>
              <input
                type="text"
                name="address"
                onChange={handleChange}
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" && user ? user.address || "" : ""}
                maxLength="200"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Cargo</label>
              <select
                className="div_custom_Select"
                name="position"
                id="position"
                onChange={handleChangePosition}
                value={cargo}
              >
                <option value=""></option>
                <option value="Administrador">Administrador</option>
                <option value="Gerente">Gerente</option>
                <option value="Vendedor(a)">Vendedor(a)</option>
                <option value="Estoquista">Estoquista</option>
              </select>
            </div>
            <div className="div_custom_UpdateItem">
              <label>Loja</label>
              <select
                className="div_custom_Select"
                name="store"
                id="store"
                onChange={handleChangeStore}
                value={store ? store : ""}
              >
                <option value=""></option>
                {stories
                  ? stories.map((item) => (
                      <option value={item.storeId}>{item.store}</option>
                    ))
                  : false}
              </select>
            </div>
            <div className="div_custom_UpdateItem">
              <label>Administrador</label>
              <Switch checked={admin} onChange={(e) => handleChangeAdmin(e)} />
              {/* <select
                className="div_custom_Select"
                name="isAdmin"
                id="isAdmin"
                onChange={handleChangeAdmin}
                value={admin}
              >
                <option value=""></option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select> */}
            </div>
            <div className="div_custom_UpdateItem">
              <Button
                size="small"
                variant="outlined"
                className="custom_mui_action"
                startIcon={<TransferWithinAStationIcon />}
                onClick={handleOpen}
              >
                Transferência Cliente
              </Button>
              <br></br>
            </div>
          </div>
          <div>
            {id !== "new" && user ? (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={updateClick}
              >
                Alterar
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={updateClick}
              >
                Cadastrar
              </Button>
            )}
            <Button
              variant="outlined"
              color="secondary"
              className="custom_add_back"
              onClick={() => nav(-1)}
            >
              Voltar
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
}
