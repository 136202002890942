import "../pages/product/product.css";
import { generateFileUrl } from "../requestMethods";

export default function VideoImg({ filesIni, item, classImg }) {
  return (
    <>
      {filesIni ? (
        item.split(".").pop() === "mp4" || item.split(".").pop() === "MP4" ? (
          <video className={classImg} autoPlay loop muted playsInline>
            <source
              className={classImg}
              src={generateFileUrl(item)}
              type="video/mp4"
            />
          </video>
        ) : (
          <img className={classImg} src={generateFileUrl(item)} />
        )
      ) : item.type === "video/mp4" ? (
        <video className={classImg} autoPlay loop muted playsInline>
          <source
            className={classImg}
            src={URL.createObjectURL(item)}
            type="video/mp4"
          />
        </video>
      ) : (
        <img className={classImg} src={URL.createObjectURL(item)} />
      )}
    </>
  );
}
