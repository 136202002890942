import "./style.css";

export function VideoSource({ handleSrcFile, file }) {
  return (
    <video className="productUploadImg2" autoPlay loop muted playsInline>
      <source
        className="productUploadImg2"
        src={handleSrcFile(file)}
        type="video/mp4"
      />
    </video>
  );
}
