import { createSlice } from "@reduxjs/toolkit";

const dataGridSlice = createSlice({
  name: "DataGridPremium",
  initialState: {
    stateGrid: {
      initialState: {
        sorting: {
          sortModel: [{ field: 'createdAt', sort: 'desc' }],
        },
        filter: {
          filterModel: {
            items: [{ field: 'enabled', operator: 'equals', value: 'Pendente' }],
          },
        },
      },
    },
    isFetching: false,
    error: false,
  },
  reducers: {
    setState: (state, action) => {
      state.isFetching = false;
      state.stateGrid = action.payload;
      state.error = false;

    },    
  },
});

export const { setState } = dataGridSlice.actions;
export default dataGridSlice.reducer;
