import { useEffect, useState, useRef } from "react";
import { generateFileUrl, userRequest } from "../../requestMethods";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import "../../global.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import dayjs from "dayjs";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { ptBR } from "@mui/x-data-grid/locales";
import { getProducts } from "../../redux/apiCalls";
import { formatPricing, formatTimeYYYYMMDD } from "../../util/helper";
import { useCompany } from "../../components/context/CompanyContext";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Container from "@mui/material/Container";

export default function Campaign() {
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const [campaign, setCampaign] = useState({});
  const [inputs, setInputs] = useState({});
  const [prdsTitle1, setprdsTitle1] = useState();
  const [selectionModel1, setSelectionModel1] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [products, setProducts] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { companyId } = useCompany();
  const [dataIni, setDateIni] = useState();

  useEffect(() => {
    setIsLoading(true);
    if (companyId > 0) {
      getProducts();
    }
    setIsLoading(false);
  }, [companyId]);

  const getProducts = async () => {
    try {
      setIsLoading(true);
      const res = await userRequest.get(`${companyId}/products/admin`);
      setProducts(res.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (id !== "new" && companyId > 0) {
      getCampaign();
    }
    setIsLoading(false);
  }, [id, companyId]);

  useEffect(() => {
    selectGrid1();
    setDateIni(dayjs(campaign.expiryDate).format("YYYY-MM-DD"));
  }, [campaign]);

  const selectGrid1 = () => {
    if (campaign.products) {
      setprdsTitle1(campaign.products);
      setSelectionModel1(() => campaign.products.map((item) => item));
    }
  };
  const getCampaign = async () => {
    try {
      const res = await userRequest.get(`${companyId}/campaign/${id}`);

      setCampaign(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const validate = () => {
    if (inputs.title === "" || inputs.title === undefined) {
      Swal.fire(
        "Atenção!",
        "Nome da Campanha deve ser preenchidos!",
        "warning"
      );
      return false;
    }
    if (inputs.expiryDate === "" || inputs.expiryDate === undefined) {
      Swal.fire("Atenção!", "Data de Validade inválida!", "warning");
      return false;
    }

    return true;
  };

  const validadePrds = () => {
    if (prdsTitle1 === undefined || prdsTitle1.length <= 0) {
      Swal.fire(
        "Atenção!",
        "Você deve selecionar no mímimo um produto!",
        "warning"
      );

      return false;
    }
    return true;
  };

  const updateClick = async (event) => {
    setIsLoading(true);

    if (id !== "new") {
      Update();
    } else {
      if (validate()) {
        if (validadePrds()) {
          Inserir();
        }
      }
    }
    setIsLoading(false);
  };

  const Update = async (event) => {
    try {
      const res = await userRequest.put(`${companyId}/campaign/${id}`, {
        title: inputs.title,
        subTitle: inputs.subTitle,
        products: prdsTitle1,
        expiryDate: dataIni,
      });
      if (Object.keys(res.data).length > 0) {
        Swal.fire("Parabéns!", "Campanha alterada com sucesso!", "success");
        nav(`../campaign`);
      } else {
        Swal.fire("Atenção!", "Erro ao alterar campanha!", "error");
      }
    } catch (error) {}
  };
  const Inserir = async () => {
    try {
      const res = await userRequest.post(`${companyId}/campaign`, {
        title: inputs.title,
        subTitle: inputs.subTitle,
        products: prdsTitle1,
        expiryDate: dataIni,
      });

      if (Object.keys(res.data).length > 0) {
        Swal.fire("Parabéns!", "Campanha inserido com sucesso!", "success");
        nav(`../campaign`);
      } else {
        Swal.fire("Atenção!", "Erro ao inserir Campanha!", "error");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  const columns = [
    {
      field: "avatar",
      headerName: "Foto",
      headerClassName: "dataGridMuiHeader",
      align: "center",
      headerAlign: "center",
      width: 50,
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <Avatar src={generateFileUrl(params.value)} />
          </>
        );
      },
    },
    {
      field: "ref",
      headerName: "Referência",
      width: 80,
      align: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "title",
      align: "left",
      headerName: "Produto",
      width: 250,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "category",
      headerName: "Categoria",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "subCategory",
      headerName: "Sub Categoria",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "discount",
      headerName: "Preço",
      width: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "percentDiscount",
      headerName: "% Desconto",
      width: 120,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "price",
      headerName: "Preço com Desconto",
      width: 160,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
  ];
  const handleChangeStart = (e) => {
    setDateIni(e.target.value);
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <div className="div_custom_Update">
        {id !== "new" ? (
          <span className="div_custom_UpdateTitle">Editar Campanha</span>
        ) : (
          <span className="div_custom_UpdateTitle">Nova Campanha</span>
        )}
        <div className="div_custom_UpdateLeft">
          <div className="div_custom_UpdateItem">
            <label>Nome da Campanha</label>
            <input
              type="text"
              className="div_custom_UpdateInput"
              name="title"
              defaultValue={id !== "new" ? campaign.title || "" : ""}
              onChange={handleChange}
              maxLength="20"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Data de Validade</label>
            <input
              type="date"
              name="expiryDate"
              value={dataIni}
              className="div_custom_UpdateInput"
              onChange={handleChangeStart}
            />
          </div>
          <br></br>
          <div className="div_custom_UpdateLeft">
            <div style={{ height: 500 }}>
              {products && products.length > 0 ? (
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBRCore.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.productId}
                  rows={products}
                  rowSelectionModel={selectionModel1}
                  onRowSelectionModelChange={(itm) => {
                    setSelectionModel1(itm);
                    setprdsTitle1(itm);
                  }}
                  columns={columns}
                  pagination
                  autoPageSize
                  checkboxSelection
                />
              ) : (
                false
              )}
            </div>
          </div>
        </div>
        <div>
          {id !== "new" && campaign ? (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<SaveIcon />}
              onClick={updateClick}
            >
              Alterar
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<SaveIcon />}
              onClick={updateClick}
            >
              Cadastrar
            </Button>
          )}
          <Button
            variant="outlined"
            color="secondary"
            className="custom_add_back"
            onClick={() => nav(-1)}
          >
            Voltar
          </Button>
        </div>
      </div>
    </Container>
  );
}
