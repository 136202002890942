/* eslint-disable jsx-a11y/alt-text */
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useCompany } from "../../components/context/CompanyContext";
import carrinho from "../../icons/carrinhos.svg";
import catalogo from "../../icons/catalogo.svg";
import cliente from "../../icons/cliente.svg";
import status_pedido from "../../icons/status_pedidos.svg";
import valor_medio from "../../icons/valor_medio.svg";
import { userRequest } from "../../requestMethods";
import {
  formatPricing,
  formatTime24hourFormat,
  formatTimeDDMMYYY,
  retunrMonth,
} from "../../util/helper";
import "./report.css";
import FormControl from "@mui/material/FormControl";
import { MultiSelect } from "react-multi-select-component";

export default function OrderReport() {
  const [ordersUser, setOrdersUser] = useState([]);
  const [analiticoCart, setAnaliticoCart] = useState([]);
  const [avgOrdersUser, setAvgOrdersUser] = useState([]);
  //const [ordersQuantity, setOrdersQuantity] = useState([]);
  //const [avgOrdersQuantity, setAvgOrdersQuantity] = useState([]);
  const [orders, setOrders] = useState();
  const [ordersCarts, setOrdersCarts] = useState();
  const [ordersAnalitico, setOrdersAnalitico] = useState();
  const [ordersDay, setOrdersDay] = useState();
  const [ordersMonth, setOrdersMonth] = useState();
  const [avgOrdersDay, setAvgOrdersDay] = useState();
  const [carts, setCarts] = useState();
  const [stories, setStories] = useState([]);
  const [dataIni, setDateIni] = useState();
  const [dataFim, setDateFim] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [readonly, setReadonly] = useState();
  const [store, setStore] = useState([]);
  const [storeIds, setStoreIds] = useState([]);
  const [selected, setSelected] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setReadonly("7");
    if (!currentUser) {
      nav(`../login`);
    }
    if (companyId > 0) {
      getStories();
      getOrdersbyMonth();
      getAvgOrdersbyDay();
      getCarts();
      getOrders();
      getOrdersbyDay();
      getOrdersbyUser();
      getAvgOrdersbyUser();
      getOrdersCarts();
    }
    setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
    setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (companyId > 0) {
      getOrdersbyMonth();
      getAvgOrdersbyDay();
      getCarts();
      getOrders();
      getOrdersbyDay();
      getOrdersbyUser();
      getAvgOrdersbyUser();
      getOrdersCarts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIni, dataFim, storeIds]);

  useEffect(() => {
    if (selected.length === stories.length) {
      return setStoreIds([]);
    }
    setStoreIds(selected.map((item) => item.value));
  }, [selected]);

  const getOrdersbyUser = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrders/ordersuser`,
          obj
        );

        setOrdersUser(
          res.data.map((order) => ({
            Total: order.total,
            managedBy: order.managedBy,
          }))
        );
      }
    } catch {}
  };
  const getAvgOrdersbyUser = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrders/avgordersuser`,
          obj
        );

        setAvgOrdersUser(
          res.data.map((order) => ({
            Total: order.total,
            managedBy: order.managedBy,
          }))
        );
      }
    } catch {}
  };

  const getOrdersbyDay = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        stories: storeIds,
      };
      if (!currentUser.isAdmin) {
        obj = {
          start: dataIni,
          end: dataFim,
          userId: currentUser.userId,
          stories: storeIds,
        };
      }

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrders/ordersday`,
          obj
        );

        setOrdersDay(
          res.data.map((order) => ({
            criado: formatTimeDDMMYYY(order.createdAt),
            Total: order.amount,
          }))
        );
      }
    } catch {}
  };
  const getOrdersbyMonth = async () => {
    try {
      const res = await userRequest.post(
        `${companyId}/reportOrders/ordersmonth`,
        { stories: storeIds }
      );

      setOrdersMonth(
        res.data.map((order) => ({
          criado: retunrMonth(order.createdAt),
          Total: order.amount,
        }))
      );
    } catch {}
  };

  const getAvgOrdersbyDay = async () => {
    try {
      const res = await userRequest.post(
        `${companyId}/reportOrders/avgordersday`,
        { stories: storeIds }
      );

      setAvgOrdersDay(
        res.data.map((order) => ({
          criado: retunrMonth(order.createdAt),
          Total: order.amount,
        }))
      );
    } catch {}
  };
  const getStories = async () => {
    const res = await userRequest.get(`${companyId}/store/stories`);
    setStories(
      res.data.map((item) => ({
        label: item.store,
        value: item.storeId,
      }))
    );
  };
  const getCarts = async () => {
    const res = await userRequest.post(`${companyId}/reportCarts/cartsTime`, {
      stories: storeIds,
    });

    setCarts(res.data);
  };
  const getAnaliticoCartsTotal = async () => {
    const res = await userRequest.post(
      `${companyId}/reportCarts/analiticoCarts`,
      { time: "total", stories: storeIds }
    );

    setAnaliticoCart(res.data);
  };
  const getAnaliticoCartsAtivos = async () => {
    const res = await userRequest.post(
      `${companyId}/reportCarts/analiticoCarts`,
      { time: "ativos", stories: storeIds }
    );
    setAnaliticoCart(res.data);
  };
  const getAnaliticoCartsParalizados = async () => {
    const res = await userRequest.post(
      `${companyId}/reportCarts/analiticoCarts`,
      { time: "paralizados", stories: storeIds }
    );
    setAnaliticoCart(res.data);
  };
  const getAnaliticoCartsInativos = async () => {
    const res = await userRequest.post(
      `${companyId}/reportCarts/analiticoCarts`,
      { time: "inativos", stories: storeIds }
    );
    setAnaliticoCart(res.data);
  };
  const getOrders = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        stories: storeIds,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrders/orders`,
          obj
        );
        setOrders(res.data);
      }
    } catch {}
  };
  const getOrdersCarts = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrders/orderscartday`,
          obj
        );
        setOrdersCarts(res.data);
      }
    } catch {}
  };

  const objOrder = (item) => {
    return {
      orderId: item.orderId,
      orderRef: item.orderRef,
      amount: item.amount,
      status: item.status,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      quantity: item.quantity,
      uniqueId: item.uniqueId,
      name: item.customer.name,
      rzSocial: item.customer.rzSocial,
      cnpj_cpf: item.customer.cnpj_cpf,
      managedBy: item.managedBy,
    };
  };
  const getOrdersAnaliticoPendentes = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        status: "Pendente",
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(`${companyId}/orders/find`, obj);

        setOrdersAnalitico(res.data.map((order) => objOrder(order)));
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getOrdersAnaliticoConfirmados = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        status: "Confirmado",
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(`${companyId}/orders/find`, obj);

        setOrdersAnalitico(res.data.map((order) => objOrder(order)));
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getOrdersAnaliticoPagos = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        status: "Pago",
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(`${companyId}/orders/find`, obj);

        setOrdersAnalitico(res.data.map((order) => objOrder(order)));
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getOrdersAnaliticoPreparados = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        status: "Preparado",
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(`${companyId}/orders/find`, obj);

        setOrdersAnalitico(res.data.map((order) => objOrder(order)));
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getOrdersAnaliticoFinalizados = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        status: "Finalizado",
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(`${companyId}/orders/find`, obj);

        setOrdersAnalitico(res.data.map((order) => objOrder(order)));
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getOrdersAnaliticoCancelado = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        status: "Cancelado",
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(`${companyId}/orders/find`, obj);

        setOrdersAnalitico(res.data.map((order) => objOrder(order)));
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };

  const handleChangeStart = (e) => {
    setDateIni(e.target.value);
  };
  const handleChangeEnd = (e) => {
    setDateFim(e.target.value);
  };
  const handleChange = (event) => {
    setReadonly(event.target.value);
    // eslint-disable-next-line default-case
    switch (event.target.value) {
      case "0":
        setDateIni(dayjs(new Date()).format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "7":
        setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "15":
        setDateIni(dayjs(new Date()).add(-15, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "30":
        setDateIni(dayjs(new Date()).add(-30, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
    }
  };
  const onButtonEditarClick = (e, row) => {
    nav(`../orders/${row}`);
  };
  const columnsCart = [
    {
      field: "name",
      headerName: "Nome",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "contact",
      headerName: "Contato",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "quantity",
      headerName: "Qtde de produtos",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "total",
      headerName: "Total",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "managedBy",
      headerName: "Vendedor(a)",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
  ];
  const columnsOrders = [
    {
      field: "orderRef",
      headerName: "Número Pedido",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "cnpj_cpf",
      headerName: "CNPJ",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "rzSocial",
      headerName: "Razão Social",
      width: 200,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "quantity",
      headerName: "Qtde Itens",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "amount",
      headerName: "Valor",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "managedBy",
      headerName: "Vendedor(a)",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "createdAt",
      headerName: "Criado",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueGetter: (value) => value && new Date(value),
      valueFormatter: (value) => {
        return `${formatTime24hourFormat(value)}`;
      },
      type: "date",
    },
    {
      field: "updatedAt",
      headerName: "Atualizado",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueGetter: (value) => value && new Date(value),
      valueFormatter: (value) => {
        return `${formatTime24hourFormat(value)}`;
      },
      type: "date",
    },
    {
      field: "itens",
      headerName: "Itens",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="text"
              size="small"
              onClick={(e) => onButtonEditarClick(e, params.row.orderId)}
            >
              Detalhes
            </Button>
          </>
        );
      },
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  const _voltarTopo = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="div_home_Container">
      <table width="100%" className="tableSpaceTab">
        <tbody>
          <tr align="right">
            <FormControl sx={{ minWidth: 300 }} size="small">
              <MultiSelect
                overrideStrings={{
                  selectAll: "Todos",
                  search: "Buscar",
                  selectSomeItems: "Lojas",
                  allItemsAreSelected: "Todas lojas selecionadas.",
                  noOptions: "Nehuma loja",
                }}
                options={stories}
                value={selected}
                onChange={setSelected}
                labelledBy="Lojas"
              />
            </FormControl>
          </tr>
          <tr>
            <div className="div_home_custom">
              <table className="table">
                <tbody>
                  <tr>
                    <td align="left" width="100%">
                      <tr>
                        <td>
                          <img src={carrinho} />
                        </td>
                        <td>
                          <tr className="labelTitle">Carrinhos</tr>
                          <tr className="labelMsgData">
                            Exibindo dados do primeiro dia do ano até hoje
                          </tr>
                        </td>
                      </tr>
                    </td>
                    <td align="left" width="0%">
                      {" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table_painel">
                <tbody>
                  <tr>
                    <td width="25%" className="status_painel">
                      Total(
                      {carts && carts.quantityTotal ? carts.quantityTotal : 0})
                    </td>
                    <td width="25%" className="status_painel">
                      Ativos(
                      {carts && carts.quantityAtual ? carts.quantityAtual : 0})
                      <span className="labelMsgDataAtivos">(Últimas 24hs)</span>
                    </td>
                    <td width="25%" className="status_painel">
                      Paralizados(
                      {carts && carts.quantityEntre ? carts.quantityEntre : 0})
                      <span className="labelMsgDataAtivos">
                        (Entre 24hs á 48hs)
                      </span>
                    </td>
                    <td width="25%" className="status_painel">
                      Inativos(
                      {carts && carts.quantityMais ? carts.quantityMais : 0})
                      <span className="labelMsgDataAtivos">
                        (Maior que 48hs)
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="25%"
                      className="labelValue_painel_black_vlr"
                      onClick={getAnaliticoCartsTotal}
                    >
                      {carts && carts.total
                        ? formatPricing(carts.total)
                        : "R$ 0,00"}
                    </td>
                    <td
                      width="25%"
                      className="labelValue_painel_black_vlr"
                      onClick={getAnaliticoCartsAtivos}
                    >
                      {carts && carts.totalAtual
                        ? formatPricing(carts.totalAtual)
                        : "R$ 0,00"}
                    </td>
                    <td
                      width="25%"
                      className="labelValue_painel_black_vlr"
                      onClick={getAnaliticoCartsParalizados}
                    >
                      {carts && carts.totalEntre
                        ? formatPricing(carts.totalEntre)
                        : "R$ 0,00"}
                    </td>
                    <td
                      width="25%"
                      className="labelValue_painel_black_vlr"
                      onClick={getAnaliticoCartsInativos}
                    >
                      {carts && carts.totalMais
                        ? formatPricing(carts.totalMais)
                        : "R$ 0,00"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </tr>
          <tr>
            <div className="div_home_custom">
              <table className="table">
                <tbody>
                  <tr>
                    <td align="left" width="100%">
                      <tr>
                        <td>
                          <img src={carrinho} />
                        </td>
                        <td>
                          <tr className="labelTitle">Analítico carrinho</tr>
                        </td>
                      </tr>
                    </td>
                    <td align="left" width="0%">
                      {" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ height: 350, width: "100%" }}>
                {analiticoCart ? (
                  <DataGridPremium
                    sx={{
                      fontFamily: "Manrope",
                      fontWeight: "500",
                      border: "0px",
                    }}
                    rowHeight={45}
                    localeText={
                      ptBRCore.components.MuiDataGrid.defaultProps.localeText
                    }
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{
                      panel: {
                        sx: {
                          top: "15px !important",
                          left: "50px !important",
                          position: "fixed !important",
                        },
                      },
                    }}
                    getRowId={(row) => row.cartId}
                    rows={analiticoCart}
                    disableSelectionOnClick
                    columns={columnsCart}
                    pageSize={8}
                  />
                ) : (
                  false
                )}
              </div>
            </div>
          </tr>
        </tbody>
      </table>
      <table width="100%" className="tableSpaceTab2">
        <tbody>
          <tr>
            <td width="50%">
              <div className="div_home_custom">
                <table className="table">
                  <tbody>
                    <tr>
                      <td align="left" width="100%">
                        <tr>
                          <td>
                            <img src={catalogo} />
                          </td>
                          <td>
                            <tr className="labelTitle">
                              Histórico mensal de vendas
                            </tr>
                            <tr className="labelMsgData">
                              Exibindo dados do primeiro dia do ano até hoje
                            </tr>
                          </td>
                        </tr>
                      </td>
                      <td align="left" width="0%">
                        {" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br></br>
                <ResponsiveContainer width="95%" height={250}>
                  <BarChart data={ordersMonth}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis
                      type="number"
                      tick={{ fontSize: 12 }}
                      tickFormatter={(tick) => {
                        return tick.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <Tooltip
                      formatter={(value) => {
                        return value.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <XAxis
                      type="category"
                      interval={0}
                      angle={-40}
                      dataKey="criado"
                      tick={{ fontSize: 12 }}
                    />
                    <Bar dataKey="Total" fill="#EE8253" barSize={5} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </td>
            <td>
              <div className="div_home_custom">
                <table className="table">
                  <tbody>
                    <tr>
                      <td align="left" width="100%">
                        <tr>
                          <td>
                            <img src={valor_medio} />
                          </td>
                          <td>
                            <tr className="labelTitle">
                              Valor médio mensal de vendas
                            </tr>
                            <tr className="labelMsgData">
                              Exibindo dados do primeiro dia do ano até hoje
                            </tr>
                          </td>
                        </tr>
                      </td>
                      <td align="left" width="0%">
                        {" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br></br>
                <ResponsiveContainer width="95%" height={250}>
                  <BarChart data={avgOrdersDay}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis
                      type="number"
                      tick={{ fontSize: 12 }}
                      tickFormatter={(tick) => {
                        return tick.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <Tooltip
                      formatter={(value) => {
                        return value.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <XAxis
                      type="category"
                      interval={0}
                      angle={-40}
                      dataKey="criado"
                      tick={{ fontSize: 12 }}
                    />
                    <Bar dataKey="Total" fill="#EE8253" barSize={5} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <div className="datePicker">
          <label className="labelPeriodo">
            Filtrar:
            <input
              type="date"
              id="startDate"
              value={dataIni}
              name="trip-start"
              min="2023-01-01"
              onChange={handleChangeStart}
              disabled={readonly !== "F"}
            />
            <input
              type="date"
              id="endDate"
              value={dataFim}
              name="trip-start"
              min="2023-01-01"
              onChange={handleChangeEnd}
              disabled={readonly !== "F"}
            />
          </label>
        </div>
        <div className="column">
          <input
            type="radio"
            id="30"
            value="30"
            onChange={handleChange}
            checked={readonly === "30"}
          />
          <label for="30">30 dias</label>
          <input
            type="radio"
            id="15"
            value="15"
            onChange={handleChange}
            checked={readonly === "15"}
          />
          <label for="15">15 dias</label>
          <input
            type="radio"
            id="7"
            value="7"
            onChange={handleChange}
            checked={readonly === "7"}
          />
          <label for="7">7 dias</label>
          <input
            type="radio"
            id="0"
            value="0"
            onChange={handleChange}
            checked={readonly === "0"}
          />
          <label for="0">Hoje</label>
          <input
            type="radio"
            id="0"
            value="F"
            onChange={handleChange}
            checked={readonly === "F"}
          />
          <label for="0">Filtro</label>
        </div>
      </div>
      <table width="100%" className="tableSpaceTab">
        <tbody>
          <tr>
            <div className="div_home_custom">
              <table className="table">
                <tbody>
                  <tr>
                    <td align="left" width="100%">
                      <tr>
                        <td>
                          <img src={status_pedido} />
                        </td>
                        <td>
                          <tr className="labelTitle">Status pedidos</tr>
                          <tr className="labelMsgData">
                            {readonly === "F"
                              ? "Exibindo dados do filtro de data"
                              : readonly === "0"
                              ? "Exibindo dados de hoje"
                              : "Exibindo dados dos últimos " +
                                readonly +
                                " dias"}
                          </tr>
                        </td>
                      </tr>
                    </td>
                    <td align="left" width="0%">
                      {" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table_painel">
                <tbody>
                  <tr>
                    <td width="16.6%" className="status_painel">
                      Pendente(
                      {orders && orders.cPendente ? orders.cPendente : 0})
                    </td>
                    <td width="16.6%" className="status_painel">
                      Confirmado(
                      {orders && orders.cConfirm ? orders.cConfirm : 0})
                    </td>
                    <td width="16.6%" className="status_painel">
                      Pago({orders && orders.cPago ? orders.cPago : 0})
                    </td>
                    <td width="16.6%" className="status_painel">
                      Preparado(
                      {orders && orders.cPreparado ? orders.cPreparado : 0})
                    </td>
                    <td width="17%" className="status_painel">
                      Finalizado(
                      {orders && orders.cFinalizado ? orders.cFinalizado : 0})
                    </td>
                    <td width="16.6%" className="status_painel">
                      Cancelado(
                      {orders && orders.cCancelado ? orders.cCancelado : 0})
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="16.6%"
                      className="labelValue_painel_black_vlr"
                      onClick={getOrdersAnaliticoPendentes}
                    >
                      {orders && orders.aPendente
                        ? formatPricing(orders.aPendente)
                        : "R$ 0,00"}
                    </td>
                    <td
                      width="16.6%"
                      className="labelValue_painel_black_vlr"
                      onClick={getOrdersAnaliticoConfirmados}
                    >
                      {orders && orders.aConfirm
                        ? formatPricing(orders.aConfirm)
                        : "R$ 0,00"}
                    </td>
                    <td
                      width="16.6%"
                      className="labelValue_painel_black_vlr"
                      onClick={getOrdersAnaliticoPagos}
                    >
                      {orders && orders.aPago
                        ? formatPricing(orders.aPago)
                        : "R$ 0,00"}
                    </td>
                    <td
                      width="16.6%"
                      className="labelValue_painel_black_vlr"
                      onClick={getOrdersAnaliticoPreparados}
                    >
                      {orders && orders.aPreparado
                        ? formatPricing(orders.aPreparado)
                        : "R$ 0,00"}
                    </td>
                    <td
                      width="17%"
                      className="labelValue_painel_black_vlr"
                      onClick={getOrdersAnaliticoFinalizados}
                    >
                      {orders && orders.aFinalizado
                        ? formatPricing(orders.aFinalizado)
                        : "R$ 0,00"}
                    </td>
                    <td
                      width="16.6%"
                      className="labelValue_painel_black_vlr"
                      onClick={getOrdersAnaliticoCancelado}
                    >
                      {orders && orders.aCancelado
                        ? formatPricing(orders.aCancelado)
                        : "R$ 0,00"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </tr>
          <tr>
            <div className="div_home_custom">
              <table className="table">
                <tbody>
                  <tr>
                    <td align="left" width="100%">
                      <tr>
                        <td>
                          <img src={valor_medio} />
                        </td>
                        <td>
                          <tr className="labelTitle">
                            Evolução diária de vendas
                          </tr>
                          <tr className="labelMsgData">
                            {readonly === "F"
                              ? "Exibindo dados do filtro de data"
                              : readonly === "0"
                              ? "Exibindo dados de hoje"
                              : "Exibindo dados dos últimos " +
                                readonly +
                                " dias"}
                          </tr>
                        </td>
                      </tr>
                    </td>
                    <td align="left" width="0%">
                      {" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br></br>
              <ResponsiveContainer width="100%" height={250}>
                <AreaChart
                  data={ordersDay}
                  height={250}
                  margin={{ top: 0, right: 0, left: 30, bottom: 0 }}
                >
                  <XAxis
                    dataKey="criado"
                    stroke="#EE8253"
                    tick={{ fontSize: 12 }}
                  />
                  <Line type="monotone" dataKey={"Total"} stroke="#EE8253" />
                  <YAxis
                    tick={{ fontSize: 12 }}
                    tickFormatter={(tick) => {
                      return tick.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      });
                    }}
                  />
                  <Tooltip
                    formatter={(value) => {
                      return value.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      });
                    }}
                  />
                  <Area
                    type="monotone"
                    dataKey={"Total"}
                    stroke="#EE8253"
                    fill="#EE8253"
                  />
                  <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5" />
                </AreaChart>
              </ResponsiveContainer>{" "}
            </div>
          </tr>
          <tr>
            <div className="div_home_custom">
              <table className="table">
                <tbody>
                  <tr>
                    <td align="left" width="100%">
                      <tr>
                        <td>
                          <img src={valor_medio} />
                        </td>
                        <td>
                          <tr className="labelTitle">
                            Evolução conversão de vendas
                          </tr>
                          <tr className="labelMsgData">
                            {readonly === "F"
                              ? "Exibindo dados do filtro de data"
                              : readonly === "0"
                              ? "Exibindo dados de hoje"
                              : "Exibindo dados dos últimos " +
                                readonly +
                                " dias"}
                          </tr>
                        </td>
                      </tr>
                    </td>
                    <td align="left" width="0%">
                      {" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br></br>
              <ResponsiveContainer width="95%" height={250}>
                <BarChart data={ordersCarts}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    interval={0}
                    angle={-40}
                    dataKey="createdAt"
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis tick={{ fontSize: 12 }} unit={"%"} />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="orderpercent"
                    name="Pedido"
                    stackId="a"
                    fill="#EE8253"
                    barSize={5}
                    unit={"%"}
                  />
                  <Bar
                    dataKey="cartpercent"
                    name="Carrinho"
                    stackId="a"
                    fill="#999999"
                    barSize={5}
                    unit={"%"}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </tr>
        </tbody>
      </table>
      <table width="100%" className="tableSpaceTab2">
        <tbody>
          <tr>
            <td width="50%">
              <div className="div_home_custom">
                <table className="table">
                  <tbody>
                    <tr>
                      <td align="left" width="100%">
                        <tr>
                          <td>
                            <img src={cliente} />
                          </td>
                          <td>
                            <tr className="labelTitle">
                              Vendas por vendedor(a)
                            </tr>
                            <tr className="labelMsgData">
                              {readonly === "F"
                                ? "Exibindo dados do filtro de data"
                                : readonly === "0"
                                ? "Exibindo dados de hoje"
                                : "Exibindo dados dos últimos " +
                                  readonly +
                                  " dias"}
                            </tr>
                          </td>
                        </tr>
                      </td>
                      <td align="left" width="0%">
                        {" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br></br>
                <ResponsiveContainer width="95%" height={250}>
                  <BarChart data={ordersUser}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis
                      type="number"
                      tick={{ fontSize: 12 }}
                      tickFormatter={(tick) => {
                        return tick.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <Tooltip
                      formatter={(value) => {
                        return value.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <XAxis
                      type="category"
                      interval={0}
                      angle={-40}
                      dataKey="managedBy"
                      tick={{ fontSize: 12 }}
                    />
                    <Bar dataKey="Total" fill="#EE8253" barSize={5} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </td>
            <td>
              <div className="div_home_custom">
                <table className="table">
                  <tbody>
                    <tr>
                      <td align="left" width="100%">
                        <tr>
                          <td>
                            <img src={cliente} />
                          </td>
                          <td>
                            <tr className="labelTitle">
                              Venda média por vendedor(a)
                            </tr>
                            <tr className="labelMsgData">
                              {readonly === "F"
                                ? "Exibindo dados do filtro de data"
                                : readonly === "0"
                                ? "Exibindo dados de hoje"
                                : "Exibindo dados dos últimos " +
                                  readonly +
                                  " dias"}
                            </tr>
                          </td>
                        </tr>
                      </td>
                      <td align="left" width="0%">
                        {" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br></br>
                <ResponsiveContainer width="95%" height={250}>
                  <BarChart data={avgOrdersUser}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis
                      type="number"
                      tick={{ fontSize: 12 }}
                      tickFormatter={(tick) => {
                        return tick.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <Tooltip
                      formatter={(value) => {
                        return value.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <XAxis
                      type="category"
                      interval={0}
                      angle={-40}
                      dataKey="managedBy"
                      tick={{ fontSize: 12 }}
                    />
                    <Bar dataKey="Total" fill="#EE8253" barSize={5} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table width="100%" className="tableSpaceTab2">
        <tbody>
          <tr>
            <div className="div_home_custom">
              <table className="table">
                <tbody>
                  <tr>
                    <td align="left" width="100%">
                      <tr>
                        <td>
                          <img src={status_pedido} />
                        </td>
                        <td>
                          <tr className="labelTitle">Analítico pedidos</tr>
                        </td>
                      </tr>
                    </td>
                    <td align="left" width="0%">
                      {" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ height: 350, width: "100%" }}>
                {ordersAnalitico ? (
                  <DataGridPremium
                    sx={{
                      fontFamily: "Manrope",
                      fontWeight: "500",
                      border: "0px",
                    }}
                    rowHeight={45}
                    localeText={
                      ptBRCore.components.MuiDataGrid.defaultProps.localeText
                    }
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{
                      panel: {
                        sx: {
                          top: "15px !important",
                          left: "50px !important",
                          position: "fixed !important",
                        },
                      },
                    }}
                    getRowId={(row) => row.orderId}
                    rows={ordersAnalitico}
                    disableSelectionOnClick
                    columns={columnsOrders}
                    pageSize={8}
                  />
                ) : (
                  false
                )}
              </div>
            </div>
          </tr>
        </tbody>
      </table>
      <div className="divRodape" onClick={_voltarTopo}>
        voltar ao topo
      </div>
    </div>
  );
}
