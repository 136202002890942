import "../../global.css";
import "../product/importProducts.css";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProducts } from "../../redux/apiCalls";
import { useState } from "react";
import * as XLSX from "xlsx";
import { userRequest } from "../../requestMethods";
import Swal from "sweetalert2";
import { generateFileUrl } from "../../requestMethods";
import { useCompany } from "../../components/context/CompanyContext";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

export default function ImportProducts() {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [dataXLS, setDataXLS] = useState([]);
  const { companyId } = useCompany();

  useEffect(() => {}, [dataXLS]);

  useEffect(() => {}, [dataXLS]);

  const columns = [
    {
      field: "categoryId",
      headerName: "ID Categoria",
      width: 1,
      align: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "category",
      align: "left",
      headerName: "Categoria",
      width: 200,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "subCategory",
      align: "left",
      headerName: "SubCategoria",
      width: 200,
      headerClassName: "dataGridMuiHeader",
    },
  ];

  const addXLS = async () => {
    if (dataXLS.length > 0) {
      const cat = Array.from(dataXLS).map((item) => ({
        category: item.category,
        subCategory: item.subCategory,
      }));

      const res = await userRequest.post(
        `${companyId}/products/categories/import`,
        cat
      );

      if (Object.keys(res.data).length > 0) {
        Swal.fire("Parabéns!", "Categorias adicionado com sucesso!", "success");
      } else {
        Swal.fire("Erro!", "Erro ao gravar as categorias!", "warning");
      }
    } else {
      Swal.fire("Atenção!", "Favor selecionar um aquivo!", "warning");
    }
  };

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });

      if (validaFile(workbook)) {
        const index = workbook.SheetNames.findIndex(
          (item) => item === "Categorias"
        );
        const sheetName = workbook.SheetNames[index];
        const sheet = workbook.Sheets[sheetName];

        sheet["A1"].w = "category";
        sheet["B1"].w = "subCategory";

        const parsedData = XLSX.utils.sheet_to_json(sheet);
        setDataXLS(parsedData);
      } else {
        Swal.fire("Erro!", "Estrutura do arquivo modificada.", "warning");
      }
    };
  };

  const validaFile = (workbook) => {
    const index = workbook.SheetNames.findIndex(
      (item) => item === "Categorias"
    );
    const sheetName = workbook.SheetNames[index];
    const sheet = workbook.Sheets[sheetName];

    if (index === -1) {
      return false;
    }
    if (sheet["A1"].v !== "Categoria") {
      return false;
    }
    if (sheet["B1"].v !== "Subcategoria") {
      return false;
    }

    return true;
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  let downalodFIle = generateFileUrl("ImportFiles/ImportarCategorias.xlsx");
  return (
    <div className="div_custom_">
      <div className="div_custom_Container">
        <div className="div_custom_UpdateList">
          <div>
            <input
              id="selecao-arquivo"
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
            />
          </div>
          <div>
            <Button
              className="custom_add_top"
              size="small"
              variant="outlined"
              color="secondary"
              onClick={addXLS}
            >
              Importar Excel
            </Button>
          </div>
          <div>
            <labe>Informações para importação de categorias</labe>
            <br></br>
          </div>
          <div className="custom_label">
            <labe>
              1. O arquivo deve ser utilizado na mesma estrutura de download.
              <a href={downalodFIle} download>
                Download Arquivo
              </a>
            </labe>
          </div>
          <div>
            <br></br>
            <DataGridPremium
              autoHeight
              sx={{
                fontFamily: "Manrope",
                fontWeight: "500",
                border: "0px",
              }}
              rowHeight={45}
              localeText={
                ptBRCore.components.MuiDataGrid.defaultProps.localeText
              }
              slots={{ toolbar: CustomToolbar }}
              slotProps={{
                panel: {
                  sx: {
                    top: "15px !important",
                    left: "50px !important",
                    position: "fixed !important",
                  },
                },
              }}
              getRowId={(row) => row.category + row.subCategory}
              rows={dataXLS}
              disableSelectionOnClick
              columns={columns}
              pageSize={8}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
