import { createSlice } from "@reduxjs/toolkit";

const titleSlice = createSlice({
  name: "title",
  initialState: {
    currentTitle: null,
    isFetching: false,
    error: null,
  },
  reducers: {
    titleSetRef: (state, action) => {
      state.isFetching = false;
      state.currentTitle = action.payload;
      state.error = false;
    },
    titleSetRefEnd: (state) => {
      state.currentTitle = null;
      state.isFetching = false;
      state.error = null;
    },
  },
});

export const { titleSetRef, titleSetRefEnd } = titleSlice.actions;
export default titleSlice.reducer;
