import { useEffect, useState } from "react";
import { login } from "../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generateFileUrl } from "../../requestMethods";
import Swal from "sweetalert2";
import { useCompany } from "../../components/context/CompanyContext";
import { logOut } from "../../redux/apiCalls";
import { urlSetRef } from "../../redux/urlSlugRedux";

const Login = () => {
  const home = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { isFetching, error } = useSelector((state) => state.user);
  const [company, setCompany] = useState("");

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const nav = useNavigate();
  const { imgLogo, companyId, slug } = useCompany();

  useEffect(() => {
    if (currentUser) {
      if (Object.keys(currentUser).length > 0 && localStorage.length > 0) {
        nav(`../${slug}/home`);
      } else {
        Swal.fire("Atenção!", "Usuário ou senha inválido!", "warning");
      }
    }
  }, [currentUser]);

  useEffect(() => {
    logOut(dispatch);
  }, []);

  const handleClick = (e) => {
    if (companyId > 0) {
      login(dispatch, { username, password }, companyId);
      const [_ignoreFirstSlash, rootParam] =
        window.location.pathname.split("/");
      dispatch(urlSetRef({ slug: rootParam }));
    }
  };

  return (
    <div>
      <div
        style={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "80px",
          background: "var(--Greys-White, #f9f9f9",
        }}
      >
        <img
          style={{
            height: "100px",
          }}
          src={generateFileUrl(imgLogo)}
        />
      </div>
      <br></br>
      <br></br>
      <div
        style={{
          background: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          padding: "60px",
          borderRadius: "15px",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        <h1 style={{ color: "gray" }}>Login</h1>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <input
            style={{ padding: 10, marginBottom: 20, width: "180px" }}
            type="text"
            placeholder="usuário"
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <input
            style={{ padding: 10, marginBottom: 20, width: "180px" }}
            type="password"
            placeholder="senha"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <button
            onClick={handleClick}
            className="button_custom_action_add_top"
          >
            Entrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
