import "./report.css";
import { useEffect, useState } from "react";
import { ptBR } from "@mui/x-data-grid/locales";
import { userRequest } from "../../requestMethods";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../components/context/CompanyContext";
import { formatTime24hourFormat } from "../../util/helper";
import dayjs from "dayjs";
import React from "react";
import Button from "@mui/material/Button";
import valor_medio from "../../icons/valor_medio.svg";
import { _convertFormatDate } from "../../util/helper";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { formatPricing } from "../../util/helper";

export default function PaymentsReport() {
  const [payments, setPayments] = useState();
  const [analitico, setAnalitico] = useState();
  const [consolidate, setConsolidate] = useState();
  const [dataIni, setDateIni] = useState();
  const [dataFim, setDateFim] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [readonly, setReadonly] = useState();

  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setReadonly("7");
    if (!currentUser) {
      nav(`../login`);
    }
    if (companyId > 0) {
      getPayments();
      getConsolidatePayments();
    }
    setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
    setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
  }, [companyId]);

  useEffect(() => {
    if (companyId > 0) {
      getPayments();
      getConsolidatePayments();
    }
  }, [dataIni, dataFim]);

  const getPayments = async () => {
    try {
      let obj = {
        paymentStart: dataIni,
        paymentEnd: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportPayments/amountPayments`,
          obj
        );

        setPayments(res.data);
      }
    } catch {}
  };
  const getConsolidatePayments = async () => {
    try {
      let obj = {
        paymentStart: dataIni,
        paymentEnd: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportPayments/consolidatePayments`,
          obj
        );

        setConsolidate(res.data);
      }
    } catch {}
  };

  const handleChangeStart = (e) => {
    setDateIni(e.target.value);
  };
  const handleChangeEnd = (e) => {
    setDateFim(e.target.value);
  };
  const handleChange = (event) => {
    setReadonly(event.target.value);
    // eslint-disable-next-line default-case
    switch (event.target.value) {
      case "0":
        setDateIni(dayjs(new Date()).format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "7":
        setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "15":
        setDateIni(dayjs(new Date()).add(-15, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "30":
        setDateIni(dayjs(new Date()).add(-30, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
    }
  };

  const getAnaliticPayment = async (ini, fim, type) => {
    try {
      let obj = {
        paymentStart: ini,
        paymentEnd: fim,
        type: type,
      };

      const res = await userRequest.post(
        `${companyId}/reportPayments/analiticPayments`,
        obj
      );
      const pedido = res.data.map((item) => ({
        orderId: item.orderId,
        orderRef: item.orderRef,
        amount: item.amount,
        status: item.status,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        quantity: item.quantity,
        uniqueId: item.uniqueId,
        name: item.customer.name,
        rzSocial: item.customer.rzSocial,
        cnpj_cpf: item.customer.cnpj_cpf,
        managedBy: item.managedBy,
        owner_cpf: item.customer.owner_cpf,
        paymentAt: item.paymentAt,
      }));
      setAnalitico(pedido);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };

  const getAnaliticPaymentTotal = () => {
    getAnaliticPayment(dataIni, dataFim, "total");
  };
  const getAnaliticPaymentPix = () => {
    getAnaliticPayment(dataIni, dataFim, "pix");
  };
  const getAnaliticPaymentDinheiro = () => {
    getAnaliticPayment(dataIni, dataFim, "dinheiro");
  };
  const getAnaliticPaymentCreditCard = () => {
    getAnaliticPayment(dataIni, dataFim, "creditCard");
  };
  const getAnaliticPaymentMooda = () => {
    getAnaliticPayment(dataIni, dataFim, "mooda");
  };
  const getConsolidatePaymentPix = (e, date) => {
    getAnaliticPayment(
      _convertFormatDate(date),
      _convertFormatDate(date),
      "pix"
    );
  };
  const getConsolidatePaymentDinheiro = (e, date) => {
    getAnaliticPayment(
      _convertFormatDate(date),
      _convertFormatDate(date),
      "dinheiro"
    );
  };
  const getConsolidatePaymentCreditCard = (e, date) => {
    getAnaliticPayment(
      _convertFormatDate(date),
      _convertFormatDate(date),
      "creditCard"
    );
  };
  const getConsolidatePaymentMooda = (e, date) => {
    getAnaliticPayment(
      _convertFormatDate(date),
      _convertFormatDate(date),
      "mooda"
    );
  };

  const columnsConsolidate = [
    {
      field: "paymentAt",
      headerName: "Pago",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "paymentTypeId1",
      headerName: "Mooda",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (params.value === null || params.value === undefined) {
          return "R$ 0,00";
        }
        return (
          <>
            <Button
              variant="text"
              onClick={(e) =>
                getConsolidatePaymentMooda(e, params.row.paymentAt)
              }
            >
              {formatPricing(params.value)}
            </Button>
          </>
        );
      },
    },
    {
      field: "paymentTypeId3",
      headerName: "Carão de Crédito",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (params.value === null || params.value === undefined) {
          return "R$ 0,00";
        }
        return (
          <>
            <Button
              variant="text"
              onClick={(e) =>
                getConsolidatePaymentCreditCard(e, params.row.paymentAt)
              }
            >
              {formatPricing(params.value)}
            </Button>
          </>
        );
      },
    },
    {
      field: "paymentTypeId2",
      headerName: "Pix",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (params.value === null || params.value === undefined) {
          return "R$ 0,00";
        }
        return (
          <>
            <Button
              variant="text"
              onClick={(e) => getConsolidatePaymentPix(e, params.row.paymentAt)}
            >
              {formatPricing(params.value)}
            </Button>
          </>
        );
      },
    },
    {
      field: "paymentTypeId4",
      headerName: "Dinheiro",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (params.value === null || params.value === undefined) {
          return "R$ 0,00";
        }
        return (
          <>
            <Button
              variant="text"
              onClick={(e) =>
                getConsolidatePaymentDinheiro(e, params.row.paymentAt)
              }
            >
              {formatPricing(params.value)}
            </Button>
          </>
        );
      },
    },
    {
      field: "total",
      headerName: "Total",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (params.value === null || params.value === undefined) {
          return "R$ 0,00";
        }
        return formatPricing(params.value);
      },
    },
  ];
  const columns = [
    {
      field: "orderRef",
      headerName: "Número Pedido",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "owner_cpf",
      headerName: "CPF proprietário",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "rzSocial",
      headerName: "Razão Social",
      width: 200,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "quantity",
      headerName: "Qtde Itens",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "amount",
      headerName: "Valor",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "managedBy",
      headerName: "Vendedor(a)",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "createdAt",
      headerName: "Criado",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueGetter: (value) => value && new Date(value),
      valueFormatter: (value) => {
        return `${formatTime24hourFormat(value)}`;
      },
      type: "date",
    },
    {
      field: "paymentAt",
      headerName: "Pago",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueGetter: (value) => value && new Date(value),
      valueFormatter: (value) => {
        return `${formatTime24hourFormat(value)}`;
      },
      type: "date",
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  const _voltarTopo = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="div_home_Container">
      <table width="100%" className="tableSpaceTab">
        <tr>
          <div>
            <div className="datePicker">
              <label className="labelPeriodo">
                Filtrar:
                <input
                  type="date"
                  id="startDate"
                  value={dataIni}
                  name="trip-start"
                  min="2023-01-01"
                  onChange={handleChangeStart}
                  disabled={readonly !== "F"}
                />
                <input
                  type="date"
                  id="endDate"
                  value={dataFim}
                  name="trip-start"
                  min="2023-01-01"
                  onChange={handleChangeEnd}
                  disabled={readonly !== "F"}
                />
              </label>
            </div>
            <div className="column">
              <input
                type="radio"
                id="30"
                value="30"
                onChange={handleChange}
                checked={readonly === "30"}
              />
              <label for="30">30 dias</label>
              <input
                type="radio"
                id="15"
                value="15"
                onChange={handleChange}
                checked={readonly === "15"}
              />
              <label for="15">15 dias</label>
              <input
                type="radio"
                id="7"
                value="7"
                onChange={handleChange}
                checked={readonly === "7"}
              />
              <label for="7">7 dias</label>
              <input
                type="radio"
                id="0"
                value="0"
                onChange={handleChange}
                checked={readonly === "0"}
              />
              <label for="0">Hoje</label>
              <input
                type="radio"
                id="0"
                value="F"
                onChange={handleChange}
                checked={readonly === "F"}
              />
              <label for="0">Filtro</label>
            </div>
          </div>
        </tr>
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tr>
                <td align="left" width="100%">
                  <tr>
                    <td>
                      <img src={valor_medio} />
                    </td>
                    <td>
                      <tr className="labelTitle">Pagamentos</tr>
                      <tr className="labelMsgData">
                        {readonly === "F"
                          ? "Exibindo dados do filtro de data"
                          : readonly === "0"
                          ? "Exibindo dados de hoje"
                          : "Exibindo dados dos últimos " + readonly + " dias"}
                      </tr>
                    </td>
                  </tr>
                </td>
                <td align="left" width="0%">
                  {" "}
                </td>
              </tr>
            </table>

            <table className="table_painel">
              <tr>
                <td width="20%" className="status_painel">
                  Total
                </td>
                <td width="20%" className="status_painel">
                  Mooda
                </td>
                <td width="20%" className="status_painel">
                  Cartão de Crédito
                </td>
                <td width="20%" className="status_painel">
                  Pix
                </td>
                <td width="20%" className="status_painel">
                  Dinheiro
                </td>
              </tr>
              <tr>
                <td
                  className="labelValue_painel_black"
                  onClick={getAnaliticPaymentTotal}
                >
                  {payments && payments.total
                    ? formatPricing(payments.total)
                    : "R$ 0,00"}
                </td>
                <td
                  className="labelValue_painel_black"
                  onClick={getAnaliticPaymentMooda}
                >
                  {payments && payments.mooda
                    ? formatPricing(payments.mooda)
                    : "R$ 0,00"}
                </td>
                <td
                  className="labelValue_painel_black"
                  onClick={getAnaliticPaymentCreditCard}
                >
                  {payments && payments.creditCard
                    ? formatPricing(payments.creditCard)
                    : "R$ 0,00"}
                </td>
                <td
                  className="labelValue_painel_black"
                  onClick={getAnaliticPaymentPix}
                >
                  {payments && payments.pix
                    ? formatPricing(payments.pix)
                    : "R$ 0,00"}
                </td>
                <td
                  className="labelValue_painel_black"
                  onClick={getAnaliticPaymentDinheiro}
                >
                  {payments && payments.dinheiro
                    ? formatPricing(payments.dinheiro)
                    : "R$ 0,00"}
                </td>
              </tr>
            </table>
          </div>
        </tr>
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tbody>
                <tr>
                  <td align="left" width="100%">
                    <tr>
                      <td>
                        <img src={valor_medio} />
                      </td>
                      <td>
                        <tr className="labelTitle">Consolidado Pagamento</tr>
                        <tr className="labelMsgData">
                          {readonly === "F"
                            ? "Exibindo dados do filtro de data"
                            : readonly === "0"
                            ? "Exibindo dados de hoje"
                            : "Exibindo dados dos últimos " +
                              readonly +
                              " dias"}
                        </tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left" width="0%">
                    {" "}
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ height: 350, width: "100%" }}>
              {consolidate ? (
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.paymentAt}
                  rows={consolidate}
                  disableSelectionOnClick
                  columns={columnsConsolidate}
                  pagination
                  autoPageSize
                />
              ) : (
                false
              )}
            </div>
          </div>
        </tr>
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tr>
                <td align="left" width="100%">
                  <tr>
                    <td>
                      <img src={valor_medio} />
                    </td>
                    <td>
                      <tr className="labelTitle">Analítico vendas</tr>
                    </td>
                  </tr>
                </td>
                <td align="left" width="0%">
                  {" "}
                </td>
              </tr>
            </table>
            <div style={{ height: 350, width: "100%" }}>
              {analitico ? (
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.orderRef}
                  rows={analitico}
                  disableSelectionOnClick
                  columns={columns}
                  pagination
                  autoPageSize
                />
              ) : (
                false
              )}
            </div>
          </div>
        </tr>
      </table>
      <div className="divRodape" onClick={_voltarTopo}>
        voltar ao topo
      </div>
    </div>
  );
}
