/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import clients from "../icons/clients.svg";
import dash from "../icons/dashboard.svg";
import home from "../icons/home.svg";
import importacao from "../icons/importacao.svg";
import photo from "../icons/photos-icon.svg";
import logoFull from "../icons/mooda_logo_full.svg";
import orderIcon from "../icons/orderIcon.svg";
import products from "../icons/products.svg";
import config from "../icons/settings.svg";
import cifrao from "../icons/cifrao.svg";
import mobile from "../icons/mobile.svg";
import discount from "../icons/discount.svg";
import youtube from "../icons/youtube.svg";
import "./sidebarComponent.css";
import { useCompany } from "../components/context/CompanyContext";
import { urlMooda } from "../requestMethods";

export default function SidebarComponent() {
  const [collapsed, setCollapsed] = useState(false);
  const { slug, showDashboard, enabledPayment } = useCompany();
  const collapse = () => {
    setCollapsed(!collapsed);
  };

  let url = urlMooda + slug + "/home";
  let urlEmployee = urlMooda + slug + "/employee";
  return (
    <Sidebar
      width="200px"
      collapsed={collapsed}
      collapsedWidth="60px"
      rootStyles={{
        backgroundImage: "linear-gradient( to right, #FF3131, #FF914C )",
        fontWeight: "700",
        color: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundImage: "linear-gradient( to right, #FF3131, #FF914C )",
          position: "fixed",
        }}
      >
        <Menu
          menuItemStyles={{
            icon: { padding: "0px" },
          }}
          rootStyles={{
            backgroundImage: "linear-gradient( to right, #FF3131, #FF914C )",
          }}
        >
          <MenuItem>
            <div className="customLogo">
              <img src={logoFull} />
            </div>
          </MenuItem>
          <MenuItem icon={<img src={home} />} component={<Link to={`home`} />}>
            Home
          </MenuItem>
          {showDashboard === true ? (
            <MenuItem
              icon={<img src={dash} />}
              component={<Link to={`tabdashboard?tab=0`} />}
            >
              {" "}
              Dashboard
            </MenuItem>
          ) : (
            false
          )}
          <MenuItem
            icon={<img src={clients} />}
            component={<Link to={`customers`} />}
          >
            Clientes
          </MenuItem>
          <MenuItem
            icon={<img src={orderIcon} />}
            component={<Link to={`orders`} />}
          >
            {" "}
            Pedidos
          </MenuItem>
          <MenuItem
            icon={<img src={products} />}
            component={<Link to={`products`} />}
          >
            {" "}
            Produtos
          </MenuItem>
          <MenuItem
            icon={<img src={discount} />}
            component={<Link to={`discounts`} />}
          >
            {" "}
            Cupom Desc.
          </MenuItem>{" "}
          <MenuItem
            icon={<img src={products} />}
            component={<Link to={`campaign`} />}
          >
            {" "}
            Campanha
          </MenuItem>{" "}
          <MenuItem
            icon={<img src={photo} />}
            component={<Link to={`collection`} />}
          >
            {" "}
            Acervo
          </MenuItem>
          <MenuItem
            icon={<img src={config} />}
            component={<Link to={`tabconfigs`} />}
          >
            {" "}
            Configurações
          </MenuItem>{" "}
          {enabledPayment === true ? (
            <MenuItem
              icon={<img src={cifrao} />}
              component={<Link to={`paymentReport`} />}
            >
              {" "}
              Extrato
            </MenuItem>
          ) : (
            false
          )}
          <MenuItem
            icon={<img src={importacao} />}
            component={<Link to={`tabimports`} />}
          >
            {" "}
            Importações
          </MenuItem>
          <MenuItem
            icon={<img src={mobile} />}
            component={
              <a href={url} target="_blank" rel="noopener noreferrer" />
            }
          >
            {" "}
            Mobile
          </MenuItem>
          <MenuItem
            icon={<img src={mobile} />}
            component={
              <a href={urlEmployee} target="_blank" rel="noopener noreferrer" />
            }
          >
            {" "}
            Vendedor(a)
          </MenuItem>
          <MenuItem
            icon={<img src={youtube} />}
            component={
              <a
                href={
                  "https://youtube.com/playlist?list=PL9ur2euvOlSzbwPtlEjVYCxMTpRqDdJmk&si=Ker9WUVCqS1syM4f"
                }
                target="_blank"
                rel="noopener noreferrer"
              />
            }
          >
            {" "}
            Tutorial
          </MenuItem>
        </Menu>
      </div>
    </Sidebar>
  );
}
