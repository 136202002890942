import { DeleteOutline } from "@mui/icons-material";
import "./style.css";

export function TrashButton({ handleClick, itemToDelete }) {
  return (
    <DeleteOutline
      className="container-images-svg"
      onClick={() => handleClick(itemToDelete)}
    />
  );
}
