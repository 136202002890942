import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Resizer from "react-image-file-resizer";
import Swal from "sweetalert2";

dayjs.extend(utc);
dayjs.extend(timezone);
export function formatPricing(value) {
  if (value) {
    const formattedValue = value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formattedValue;
  }
}

export function formatPricingWithOutRS(value) {
  if (value) {
    const formattedValue = new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
    }).format(value);
    return formattedValue;
  }
}

export function formatPricingDatabase(value) {
  if (value) {
    const formattedValue = Number(value.replace("R$", "").replace(",", "."));
    return formattedValue;
  }
}

export const formatTime24hourFormat = (value) => {
  if (value) {
    const formattedValue = dayjs(value)
      .tz("America/Sao_Paulo")
      .format("DD/MM/YYYY HH:mm:ss");
    return formattedValue;
  }
};

export const formatTimeDDMMYYY = (value) => {
  if (value) {
    const formattedValue = dayjs(value)
      .tz("America/Sao_Paulo")
      .format("DD/MM/YYYY");
    return formattedValue;
  }
};
export const formatTimeYYYYMMDD = (value) => {
  if (value) {
    const formattedValue = dayjs(value)
      .tz("America/Sao_Paulo")
      .format("YYYY-MM-DD");
    return formattedValue;
  }
};
export const retunrMonth = (value) => {
  var months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  if (value) {
    return months[value - 1];
  }
};
export const returnFormData = async (files, resize) => {
  const formData = new FormData();

  let photo;
  try {
    if (files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        if (files[i].type !== "video/mp4") {
          photo = await resizeFile(files[i], resize);
          formData.append("image", photo);
        } else {
          formData.append("image", files[i]);
        }
      }
      return formData;
    }
  } catch (e) {
    console.error(e);
  }
};
//https://github.com/onurzorluer/react-image-file-resizer#readme
export const resizeFile = (file, resize) =>
  new Promise((resolve) => {
    let maxHeight = resize === "foto" ? 1350 : 1080;

    Resizer.imageFileResizer(
      file,
      1080,
      maxHeight,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
      1080,
      maxHeight
    );
  });

export const returnValidaVideo = (inp_files) => {
  if (inp_files.length > 0) {
    for (var i = 0; i < inp_files.length; i++) {
      if (inp_files[i].type === "video/mp4" && inp_files[i].size > 4194304) {
        Swal.fire("Atenção!", "O tamanho máximo do vídeo é 4MB.", "warning");
        return false;
      }
    }
  }
  return true;
};

export const _removerAcentosECaracteresEspeciais = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f|\u00b4|\u0060|\u005e|\u007e]/g, "")
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .replace(" ", "_");
};
export const _removerAcentos = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const _convertFormatDate = (date) => {
  const parts = date.split("/");

  const year = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10) - 1;
  const day = parseInt(parts[0], 10);

  return new Date(year, month, day);
};
export const formatCpfOrCnpj = (input) => {
  if (!input) return "";

  if (!input.length) return input;

  const numericInput = input.replace(/\D/g, "");

  if (!numericInput) {
    return "";
  }

  if (numericInput.length === 11) {
    return formatCPF(numericInput);
  }
  if (numericInput.length === 14) {
    return formatCNPJ(numericInput);
  }
  return input;
};

function formatCPF(cpf) {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

function formatCNPJ(cnpj) {
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}
