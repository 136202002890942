import "./featuredInfo.css";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";
import { useSelector } from "react-redux";
import { useCompany } from "../../components/context/CompanyContext";
import { formatPricing,formatTime24hourFormat } from "../../util/helper";


export default function FeaturedInfo(props) {
  const [dataStatus, setDataStatus] = useState([]);
  const [cPendente, setCPendente] = useState(0);
  const [cConfirmado, setCConfirmado] = useState(0);
  const [cCancelado, setCCancelado] = useState(0);
  const currentUser = useSelector((state) => state.user.currentUser);
  const{companyId} = useCompany();


  useEffect(() => {

    if(companyId >0){
      getCustomerGroupByStatus();
    }
  }, [companyId]);

  useEffect(() => {

    if(companyId >0){
      getCustomerGroupByStatus();
    }
  }, [props.dataIni,props.dataFim]);
  const getCustomerGroupByStatus = async () => {
    try {
      let url="";
      if(currentUser.isAdmin){
        url = `${companyId}/customers/groupby/${props.dataIni}&${props.dataFim}`;
      }else{
        url = `${companyId}/customers/groupbyuser/${currentUser.userId}&${props.dataIni}&${props.dataFim}`;
      }
      
      const res = await userRequest.get(url);

      setDataStatus(res.data)
    } catch {}
  };

  useEffect(() => {

    dataStatus.map((item) => {
      // eslint-disable-next-line default-case
      switch (item.enabled) {
        case "Pendente":
          setCPendente(item.count);
          break;
        case "Aprovado":
          setCConfirmado(item.count);
          break;        
            case "Negado":
              setCCancelado(item.count);
              break;            
      }
    })

  }, [dataStatus]);

  return (
    <>
    <div className="featured">
      <div className="featuredItem">
        <span className="featuredTitle">Novos</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{cPendente + cConfirmado}</span>
        </div>
      </div>

      <div className="featuredItem">
        <span className="featuredTitle">Pendentes</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{cPendente}</span>
        </div>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Aprovados</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{cConfirmado}</span>
        </div>
      </div>

      <div className="featuredItem">
        <span className="featuredTitle">Negados</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{cCancelado}</span>
        </div>
      </div>
    </div>
      </>

  );
}
