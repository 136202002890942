import "../product/product.css"
import "../../global.css";
import "../../components/DragDropFiles.css";
import { useEffect, useState } from "react";
import {
    publicRequestSt,
} from "../../requestMethods";
import * as React from "react";

export default function Paleta() {

  const [colors, setColors] = useState([{}]);

  useEffect(() => {
    getColors();
  }, []);

  const getColors = async () => {
    const res = await publicRequestSt.get("/standard/colors");
    setColors(res.data);
  };
 

  return (
    <div className="div_custom_">
      <div className="div_custom_Container">
        <div className="div_custom_Update">
          <div className="div_custom_UpdateLeft">
            <div>
              <table className="custom-minigrid-prd">
                <thead>
                  <tr>
                    <td>Cores</td>
                    <td>Nomes</td>
                  </tr>
                </thead>
                <tbody>
                  {colors.map((color, iColor) => (
                    <tr key={color.ref}>
                      <td bgcolor={color.rgb}></td>
                      <td>{color.label}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
