import "./invoice.css";
import * as React from "react";
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethods";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { generateFileUrl } from "../../requestMethods";
import {
  formatPricing,
  formatTime24hourFormat,
  formatTimeDDMMYYY,
  formatCpfOrCnpj,
} from "../../util/helper";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { useCompany } from "../../components/context/CompanyContext";
import { useNavigate } from "react-router-dom";

export default function Invoice() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const location = useLocation();
  const orderId = location.pathname.split("/")[3];
  const [order, setOrder] = useState();
  const [resume, setResume] = useState();
  const [company, setCompany] = useState();
  const [st, setStatus] = useState();
  const { companyId, name, logo } = useCompany();
  const nav = useNavigate();

  useEffect(() => {
    if (companyId > 0) {
      getCompany();
      getOrders();
    }
  }, [companyId]);

  const getCompany = async () => {
    try {
      const res = await userRequest.get(`${companyId}/company`);

      setCompany(res.data);
    } catch (error) {}
  };
  const getOrders = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/orders/2691f1b5-d4f2-41cb-b77c-1a31074a9734`
      );

      setOrder(res.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  function returnAddress(address) {
    let street, cep;
    if (address) {
      street = address.street
        ? address.street
        : "" + "," + address.numAddress
        ? address.numAddress
        : "" + " - " + address.complement
        ? address.complement
        : "";

      cep = address.cep
        ? address.cep
        : "" + " - " + address.city
        ? address.city
        : "" + " - " + address.state
        ? address.state
        : "";
    }
    return `${street}<br>${cep}`;
  }
  function returnItens(products) {
    let itens = "";

    if (products) {
      products.map(
        (prd) =>
          (itens += (
            <tr>
              <td width="10%" align="left">
                {prd.ref}
              </td>
              <td width="45%" align="left">
                {prd.title}
              </td>
              <td width="10%" align="center">
                {prd.quantity}
              </td>
              <td width="15%" align="center">
                {formatPricing(prd.price)}
              </td>
              <td width="15%" align="center">
                {formatPricing(prd.quantity * prd.price)}
              </td>
            </tr>
          ))
      );
    }
    return itens;
  }
  function getDiscount() {
    let vl = order && order.discount ? order.discount / 100 : 0;

    return order ? order.amount * vl : 0;
  }
  function getTotalOrder() {
    let delivery =
      order && order.delivery?.VlTotal > 0 ? order.delivery?.VlTotal : 0;
    return order && order.amount - getDiscount() + delivery;
  }

  return (
    <div class="invoice-box">
      <div className="divlogoimg">
        <img
          className="logoimg"
          src="https://mooda-space.nyc3.digitaloceanspaces.com/1/logo/5024cfed-e0a4-4c9b-9a69-efb47915cabb.PNG"
        ></img>
      </div>
      <h3 class="labelHead">Comprovante de Pagamento</h3>
      <div>
        <span className="negrito">Pedido: </span>
        <span>{order && order.orderRef}</span>
      </div>
      <div>
        <span className="negrito">Criado: </span>
        <span>{order && formatTime24hourFormat(order.createdAt)}</span>
      </div>
      <div>
        <span className="negrito">Pago: </span>
        <span>{order && formatTime24hourFormat(order.paymentAt)}</span>
      </div>
      <br></br>
      <div className="negrito">Empresa</div>
      <div>{company && company.name}</div>
      <div>{company && formatCpfOrCnpj(company.cnpj)}</div>
      <div>{returnAddress(company && company.address)}</div>
      <br></br>
      <div className="negrito">Cliente</div>
      <div>{order && order.customer.name}</div>
      <div> {order && formatCpfOrCnpj(order.customer.cnpj_cpf)}</div>
      <div> {returnAddress(order && order.customer.address)}</div>
      <br></br>
      <table cellSpacing="0" cellPadding="1" align="center" className="table">
        <tbody>
          <tr className="heading">
            <td width="10%" align="left">
              Ref.
            </td>
            <td width="45%" align="left">
              Item
            </td>
            <td width="10%" align="center">
              Qtde
            </td>
            <td width="15%" align="center">
              Vlr. unitário
            </td>
            <td width="15%" align="center">
              Preço
            </td>
          </tr>
          <tr>
            <td width="10%" align="left">
              {/* {prd.ref} */}
            </td>
            <td width="45%" align="left">
              {/* {prd.title} */}
            </td>
            <td width="10%" align="center">
              {/* {prd.quantity} */}
            </td>
            <td width="15%" align="center">
              {/* {formatPricing(prd.price)} */}
            </td>
            <td width="15%" align="center">
              {/* {formatPricing(prd.quantity * prd.price)} */}
            </td>
          </tr>
        </tbody>
      </table>
      <br></br>
      <div>
        <span className="negrito">Número de peças: </span>
        <span>{order && order.quantity} pc.</span>
      </div>
      <div>
        <span className="negrito">Subtotal: </span>
        <span>{formatPricing(order && order.amount)}</span>
      </div>
      <div>
        <span className="negrito">Desconto: </span>
        <span>{formatPricing(getDiscount())}</span>
      </div>

      <div>
        <span className="negrito">Frete: </span>
        <span>
          {" "}
          {order && order.delivery?.VlTotal > 0
            ? formatPricing(order.delivery?.VlTotal)
            : "R$ 0,00"}
        </span>
      </div>

      <div>
        <span className="negrito">Total </span>
        <span>{formatPricing(getTotalOrder())}</span>
      </div>
    </div>
  );
}
