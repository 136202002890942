import { createSlice } from "@reduxjs/toolkit";

const dataGridProductSlice = createSlice({
  name: "dataGridProductPremium",
  initialState: {
    stateGrid: {
      initialState: {
        sorting: {
          sortModel: [
            { field: "updatedAt", sort: "desc" },
            { field: "updateImages", sort: "desc" },
          ],
        },
      },
    },
    isFetching: false,
    error: false,
  },
  reducers: {
    setState: (state, action) => {
      state.isFetching = false;
      state.stateGrid = action.payload;
      state.error = false;
    },
  },
});

export const { setState } = dataGridProductSlice.actions;
export default dataGridProductSlice.reducer;
