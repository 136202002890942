import "../../global.css";
import "./productList.css";
import "./importProducts.css";
import "../config/paleta";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../redux/apiCalls";
import { useState } from "react";
import * as XLSX from "xlsx";
import { userRequest } from "../../requestMethods";
import Swal from "sweetalert2";
import { formatPricing, _removerAcentos } from "../../util/helper";
import Loading from "../../components/loading/loading";
import { useCompany } from "../../components/context/CompanyContext";
import { generateFileUrl } from "../../requestMethods";
import Button from "@mui/material/Button";

export default function ImportProducts() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [dataXLS, setDataXLS] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useCompany();

  useEffect(() => {
    getProducts(dispatch, true, companyId);
  }, [dispatch, companyId]);

  useEffect(() => {}, [dataXLS]);

  const columns = [
    {
      field: "productId",
      headerName: "ID Produto",
      width: 1,
      align: "left",
      hideable: false,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "title",
      align: "left",
      headerName: "Produto",
      width: 200,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "ref",
      headerName: "Referência",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "shortDesc",
      headerName: "Descrição Curta",
      width: 200,
      align: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "desc",
      headerName: "Descrição",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "price",
      headerName: "Preço com desconto",
      width: 160,
      align: "left",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (params) => formatPricing(params?.value),
    },
    {
      field: "discount",
      headerName: "Preço",
      width: 200,
      align: "left",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (params) => formatPricing(params?.value),
    },
    {
      field: "colors",
      headerName: "Cores",
      width: 200,
      headerClassName: "dataGridMuiHeader",
      align: "left",
    },
    {
      field: "sizes",
      headerName: "Tamanhos",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "category",
      headerName: "Categoria",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "subCategory",
      headerName: "Subcategoria",
      width: 200,
      align: "left",
      headerClassName: "dataGridMuiHeader",
    },
  ];

  const addXLS = async () => {
    setIsLoading(true);
    if (dataXLS.length > 0) {
      const product = Array.from(dataXLS).map((item) => ({
        title: item.title,
        ref: item.ref.toString(),
        shortDesc: item.shortDesc,
        desc: item.desc,
        price: item.price,
        discount: item.discount,
        colors: item.colors ? item.colors.split(",") : ["Não informada"],
        sizes: item.sizes ? item.sizes.split(",") : ["Não informada"],
        lastUserUpdate: currentUser.userId,
        category: item.subCategory ? item.subCategory : item.category,
      }));

      const res = await userRequest.post(
        `${companyId}/products/import`,
        product
      );

      if (Object.keys(res.data).length > 0) {
        Swal.fire("Parabéns!", "Produtos adicionado com sucesso!", "success");
      }
    } else {
      Swal.fire("Atenção!", "Favor selecionar um aquivo!", "warning");
    }
    setIsLoading(false);
  };

  const handleFileUpload = (e) => {
    setIsLoading(true);
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });

      if (validaFile(workbook)) {
        const index = workbook.SheetNames.findIndex(
          (item) => item === "Produtos"
        );
        const sheetName = workbook.SheetNames[index];
        const sheet = workbook.Sheets[sheetName];

        sheet["A1"].w = "title";
        sheet["B1"].w = "ref";
        sheet["C1"].w = "shortDesc";
        sheet["D1"].w = "desc";
        sheet["E1"].w = "price";
        sheet["F1"].w = "discount";
        sheet["G1"].w = "category";
        sheet["H1"].w = "subCategory";
        sheet["I1"].w = "sizes";
        sheet["J1"].w = "colors";

        const parsedData = XLSX.utils.sheet_to_json(sheet, { raw: false });

        const newParseData = parsedData.map((item) => ({
          title: item.title ? item.title.toUpperCase() : null,
          ref: item.ref ? item.ref.replace(",", ".").toUpperCase() : null,
          shortDesc: item.shortDesc ? item.shortDesc.toUpperCase() : null,
          desc: item.desc ? item.desc.toUpperCase() : null,
          price: item.price ? item.price : item.discount,
          discount: item.discount,
          category: item.category ? item.category.toUpperCase() : null,
          subCategory: item.subCategory ? item.subCategory.toUpperCase() : null,
          sizes: item.sizes ? _removerAcentos(item.sizes.toUpperCase()) : null,
          colors: item.colors
            ? _removerAcentos(item.colors.toUpperCase())
            : null,
        }));
        setDataXLS(newParseData);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        Swal.fire("Erro!", "Estrutura do arquivo modificada.", "warning");
      }
    };
    setIsLoading(false);
  };

  const validaFile = (workbook) => {
    const index = workbook.SheetNames.findIndex((item) => item === "Produtos");
    const sheetName = workbook.SheetNames[index];
    const sheet = workbook.Sheets[sheetName];

    if (index === -1) {
      return false;
    }
    if (sheet["A1"].v !== "Produto") {
      return false;
    }
    if (sheet["B1"].v !== "Referencia") {
      return false;
    }
    if (sheet["C1"].v !== "Breve Descrição") {
      return false;
    }
    if (sheet["D1"].v !== "Descrição") {
      return false;
    }
    if (sheet["E1"].v !== "Preço com desconto") {
      return false;
    }
    if (sheet["F1"].v !== "Preço") {
      return false;
    }
    if (sheet["G1"].v !== "Categoria") {
      return false;
    }
    if (sheet["H1"].v !== "Subcategoria") {
      return false;
    }
    if (sheet["I1"].v !== "Tamanhos") {
      return false;
    }
    if (sheet["J1"].v !== "Cores") {
      return false;
    }

    return true;
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  let downalodFIle = generateFileUrl("ImportFiles/ImportarProdutos.xlsx");
  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateList">
            <div>
              <input
                id="selecao-arquivo"
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
              />
            </div>
            <div>
              <Button
                className="custom_add_top"
                size="small"
                variant="outlined"
                color="secondary"
                onClick={addXLS}
              >
                Importar Excel
              </Button>
            </div>
            <div>
              <labe>Informações para importação de produtos</labe>
              <br></br>
            </div>
            <div className="custom_label">
              <labe>
                1. O arquivo deve ser utilizado na mesma estrutura de download.
                <a href={downalodFIle} download>
                  Download Arquivo
                </a>
              </labe>
            </div>
            <div className="custom_label">
              <labe>
                2. As numerações e cores informadas no arquivo deverão ser da
                grade e cores selecionados nas configurações de{" "}
                <a href={`colorsize`}>tamanhos e cores</a>.Caso a numerão não
                exista, favor falar com nosso suporte.
              </labe>
            </div>
            <div>
              <br></br>
              <DataGridPremium
                autoHeight
                sx={{
                  fontFamily: "Manrope",
                  fontWeight: "500",
                  border: "0px",
                }}
                rowHeight={45}
                localeText={
                  ptBRCore.components.MuiDataGrid.defaultProps.localeText
                }
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                  panel: {
                    sx: {
                      top: "15px !important",
                      left: "50px !important",
                      position: "fixed !important",
                    },
                  },
                }}
                getRowId={(row) => row.title + row.ref}
                rows={dataXLS}
                disableSelectionOnClick
                columns={columns}
                pageSize={8}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
