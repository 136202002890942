import { loginFailure, loginStart, loginSuccess, loginEnd } from "./userRedux";
import { publicRequest, userRequest } from "../requestMethods";
import {
  getProductFailure,
  getProductStart,
  getProductSuccess,
  deleteProductFailure,
  deleteProductStart,
  deleteProductSuccess,
  updateProductFailure,
  updateProductStart,
  updateProductSuccess,
  addProductFailure,
  addProductStart,
  addProductSuccess,
} from "./productRedux";
import { persistor } from "./store";
import Swal from "sweetalert2";

export const login = async (dispatch, user, companyId) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post(
      `${companyId}/users/loginEmployee`,
      user
    );
    dispatch(loginSuccess(res.data));
    persistor.flush();
  } catch (err) {
    Swal.fire("Atenção!", "Usuário ou senha inválido!", "warning");
    dispatch(loginFailure());
  }
};

export const getProducts = async (dispatch, full, companyId) => {
  dispatch(getProductStart());
  try {
    let api = `${companyId}/products`;
    if (full) {
      api += "/admin";
    } else {
      api += "?page=1&limit=5000";
    }
    const res = await userRequest.get(api);

    dispatch(getProductSuccess(res.data));
  } catch (err) {
    dispatch(getProductFailure());
  }
};

export const deleteProduct = async (id, dispatch) => {
  dispatch(deleteProductStart());
  try {
    // const res = await userRequest.delete(`/products/${id}`);
    dispatch(deleteProductSuccess(id));
  } catch (err) {
    dispatch(deleteProductFailure());
  }
};

export const updateProduct = async (id, product, dispatch) => {
  dispatch(updateProductStart());
  try {
    // update
    dispatch(updateProductSuccess({ id, product }));
  } catch (err) {
    dispatch(updateProductFailure());
  }
};
export const addProduct = async (product, dispatch, companyId) => {
  dispatch(addProductStart());
  try {
    const res = await userRequest.post(`${companyId}/products`, product);
    dispatch(addProductSuccess(res.data));
  } catch (err) {
    dispatch(addProductFailure());
  }
};
export const logOut = async (dispatch) => {
  try {
    dispatch(loginEnd());
  } catch (err) {}
};
