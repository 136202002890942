import "../../global.css";
import "./discountList.css";
import dayjs from "dayjs";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { ptBR } from "@mui/x-data-grid/locales";
import { useEffect } from "react";
import { useState } from "react";
import { userRequest } from "../../requestMethods";
import IconButton from "@mui/material/IconButton";
import { formatTimeDDMMYYY } from "../../util/helper";
import { DeleteOutline } from "@mui/icons-material";
import { useCompany } from "../../components/context/CompanyContext";
import Swal from "sweetalert2";
import Loading from "../../components/loading/loading";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { titleSetRef } from "../../redux/titleRedux";
export default function DiscountList() {
  const [discounts, setDiscounts] = useState([{}]);
  const { companyId } = useCompany();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const [uniqueDiscount, setUniqueDiscount] = useState(false);
  const [inputs, setInputs] = useState({});
  const [date, setDate] = useState();
  const [dateNow, setDateNow] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(titleSetRef({ title: "Cupom" }));
    if (companyId > 0) {
      setDate(dayjs(new Date()).add(+1, "day").format("YYYY-MM-DD"));
      setDateNow(dayjs(new Date()).format("YYYY-MM-DD"));
      getDiscounts();
    }
  }, [companyId]);

  const getDiscounts = async () => {
    try {
      const res = await userRequest.get(`${companyId}/discounts`);
      setDiscounts(res.data);
    } catch {
      console.log();
    }
  };
  const addDiscount = async () => {
    let valida = false;
    if (
      inputs.codeDiscount !== "" &&
      inputs.codeDiscount !== undefined &&
      inputs.discount !== "" &&
      inputs.discount !== undefined
    ) {
      valida = true;
    } else {
      Swal.fire(
        "Atenção!",
        "Os campos Cupom e % Desconto devem ser preenchidos.",
        "warning"
      );
    }

    if (valida) {
      if (date >= dateNow) {
        let disc = discounts.find(
          (item) =>
            item.codeDiscount === inputs.codeDiscount.toString().toUpperCase()
        );
        if (!disc) {
          const res = await userRequest.post(`${companyId}/discounts`, {
            codeDiscount: inputs.codeDiscount.toUpperCase(),
            expiryDate: date,
            createdBy: currentUser.userId,
            companyId: companyId,
            discount: inputs.discount,
            uniqueDiscount: uniqueDiscount,
          });
          if (Object.keys(res.data).length > 0) {
            Swal.fire("Parabéns!", "Cupom criada com sucesso!", "success");
            getDiscounts();
          }
        } else {
          Swal.fire("Atenção!", "Cupom de desconto existente!", "warning");
        }
      } else {
        Swal.fire("Atenção!", "O campo Cupom deve ser preenchido.", "warning");
      }
    }
  };
  const deleteDiscount = async (cp) => {
    const res = await userRequest.put(`${companyId}/discounts/${cp}`, {
      deleted: dateNow,
      lastUserUpdate: currentUser.userId,
    });

    if (res.data > 0) {
      getDiscounts();
    } else {
      Swal.fire("Atenção!", "Erro ao excluir desconto!", "error");
    }
  };
  const onButtonDeleteClick = async (e, row) => {
    if (currentUser.isAdmin) {
      Swal.fire({
        title: "Tem certeza que deseja excluir?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EE8253",
        confirmButtonText: "Sim, excluir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          deleteDiscount(row);
        }
      });
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para excluir o desconto!",
        "warning"
      );
    }
  };
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const columns = [
    // {
    //   field: "action",
    //   headerName: "",
    //   width: 80,
    //   align: "center",
    //   headerAlign: "center",
    //   headerClassName: "dataGridMuiHeader",
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <IconButton
    //           onClick={(e) => onButtonEditarClick(e, params.row.discountId)}
    //         >
    //           <EditIcon />
    //         </IconButton>
    //       </>
    //     );
    //   },
    // },
    {
      field: "codeDiscount",
      align: "left",
      headerName: "Cupom",
      width: 100,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "discount",
      align: "center",
      headerAlign: "center",
      headerName: "% Desconto",
      width: 150,
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "expiryDate",
      headerName: "Data expiração",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      type: "date",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "createdBy",
      headerName: "Criado por",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "uniqueDiscount",
      headerName: "Usar 1 vez",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        // eslint-disable-next-line default-case
        if (params.value) {
          return "SIM";
        } else {
          return "NÃO";
        }
      },
    },
    {
      field: "createdAt",
      headerName: "Criada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "deleted",
      headerName: "Inativado",
      width: 150,
      align: "center",
      headerAlign: "center",
      type: "date",
      valueFormatter: (value) => {
        if (value === undefined || value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "deletar",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (!params.row.deleted) {
          return (
            <IconButton
              onClick={(e) => onButtonDeleteClick(e, params.row.discountId)}
            >
              <DeleteOutline />
            </IconButton>
          );
        }
      },
    },
  ];
  const handleChangeStart = (e) => {
    setDate(e.target.value);
  };
  const handleChangeUniqueDiscount = (e) => {
    //setAdmin(e.target.value);
    setUniqueDiscount(e.target.checked);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateList">
            <div>
              <table style={{ borderSpacing: "0px" }}>
                <tr style={{ padding: "0px" }}>
                  <td className="tdCustom">Cupom</td>
                  <td>% Desconto</td>
                  <td>Data expiração</td>
                  <td>Utilizar apenas 1 vez?</td>
                </tr>
                <tr style={{ padding: "0px" }}>
                  <td className="tdCustom">
                    <input
                      className="div_custom_UpdateInputShort"
                      style={{ margin: "0px", width: "150px" }}
                      type="text"
                      name="codeDiscount"
                      onChange={handleChange}
                    />
                  </td>
                  <td className="tdCustom">
                    <input
                      className="div_custom_UpdateInputShort"
                      style={{ margin: "0px 5px 0px 0px", width: "150px" }}
                      type="number"
                      name="discount"
                      onChange={handleChange}
                      maxLength="100"
                    />
                  </td>
                  <td className="datePickerDis">
                    <input
                      type="date"
                      id="expiryDate"
                      value={date}
                      name="trip-start"
                      min={dateNow}
                      onChange={handleChangeStart}
                      // disabled={readonly !== "F"}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Switch
                      defaultChecked={false}
                      onChange={(e) => handleChangeUniqueDiscount(e)}
                    />
                  </td>
                </tr>
              </table>
              {/* <label className="custom_label">Nome Categoria:</label>
              <input
                type="text"
                name="category"
                onChange={handleChange}
                maxLength="100"
              /> */}
            </div>
            <div style={{ padding: "0px" }}>
              <Button
                variant="outlined"
                color="secondary"
                className="custom_mui_action"
                startIcon={<SaveIcon />}
                onClick={addDiscount}
              >
                Adicionar Cupom
              </Button>
            </div>
            <div className="div_custom_UpdateLeft">
              <div style={{ height: 500 }}>
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.discountId + row.codeDiscount}
                  rows={discounts}
                  disableSelectionOnClick
                  columns={columns}
                  pagination
                  autoPageSize
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
