import "../../global.css";
import "./color_sizes.css";
import "../../components/DragDropFiles.css";
import { useEffect, useState } from "react";
import { userRequest, publicRequestSt } from "../../requestMethods";
import * as React from "react";
import Swal from "sweetalert2";
import Container from "@mui/material/Container";
import { useCompany } from "../../components/context/CompanyContext";
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import { Visibility } from "@mui/icons-material";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

export default function ColorsSizes() {
  const [colors, setColors] = useState([{}]);
  const [sizes, setSizes] = useState([{}]);
  const [colorsCompany, setColorsCompany] = useState([]);
  const [sizesCompany, setSizeCompany] = useState([]);
  const [grid, setGrid] = useState([]);
  const [selectionModel1, setSelectionModel1] = useState();
  const [colorTitle, setcolorTitle] = useState([{}]);
  const { companyId } = useCompany();
  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    if (companyId > 0) {
      getColors();
      getSizes();

      if (sizesCompany.length === 0) {
        getSizesCompany();
      }
      if (colorsCompany.length === 0) {
        getColorsCompany();
      }
      if (grid.length === 0) {
        getGrid();
      }
    }
  }, [companyId]);

  const getColorsCompany = async () => {
    const res = await userRequest.get(`${companyId}/products/colors`);

    res.data.map((item) => {
      setColorsCompany((prev) => [...prev, item.id]);
    });
  };
  const getSizesCompany = async () => {
    const resCp = await userRequest.get(`${companyId}/products/sizes`);

    resCp.data.map((item) => setSizeCompany((prev) => [...prev, item.id]));
  };

  const getGrid = async () => {
    const res = await userRequest.get(`${companyId}/grid`);
    setGrid(res.data);
  };
  const getColors = async () => {
    const res = await userRequest.get(`${companyId}/products/colors/full`);

    setColors(res.data);
  };

  const getSizes = async () => {
    const res = await publicRequestSt.get("/standard/sizes");
    setSizes(res.data);
  };
  const cadastrarClick = () => {
    if (validateProductColor()) {
      createColorSize();
    }
  };

  const createColorSize = async () => {
    const arrayColors = colorsCompany.map((item) =>
      colors.find((c) => c.colorId === item)
    );
    const arraySizes = sizesCompany.map((item) =>
      sizes.find((s) => s.sizeId === item)
    );

    const resColor = await userRequest.post(
      `${companyId}/products/colors`,
      arrayColors
    );
    const resSize = await userRequest.post(
      `${companyId}/products/sizes`,
      arraySizes
    );

    if (
      Object.keys(resColor.data).length > 0 &&
      Object.keys(resSize.data).length > 0
    ) {
      Swal.fire("Parabéns!", "Cores e Tamanhos cadastrados!", "success");
    } else {
      Swal.fire("Erro!", "Erro ao gravar Cores e Tamanhos!", "error");
    }
  };

  const deleteColor = async (colorId) => {
    const res = await userRequest.delete(
      `${companyId}/products/colors/${colorId}`
    );
  };
  const deleteSize = async (sizeId) => {
    const res = await userRequest.delete(
      `${companyId}/products/sizes/${sizeId}`
    );
  };
  const handleChangeSize = (e) => {
    if (e.target.checked) {
      setSizeCompany((prev) => [...prev, parseInt(e.target.value)]);
    } else {
      const existProduct = grid.find(
        (g) => g.sizeId === parseInt(e.target.value)
      );
      if (!existProduct) {
        setSizeCompany((prev) => [
          ...prev.filter((item) => !(item === parseInt(e.target.value))),
        ]);
        deleteSize(parseInt(e.target.value));
      } else {
        Swal.fire(
          "Atenção!",
          "Você não pode excluir esse tamanho pois existem produtos atrelados a ele.",
          "warning"
        );
      }
    }
  };
  const validateProductColor = () => {
    const colorIdsArray = grid.reduce((acc, obj) => {
      if (!acc.includes(obj.colorId)) {
        acc.push(obj.colorId);
      }
      return acc;
    }, []);

    // Obter os IDs das categorias que têm produtos
    const colorProducts = colorsCompany.map((c) => c);

    // Filtrar as categorias que não estão na lista de categorias com produtos
    const gridSemColor = colorIdsArray.filter(
      (g) => !colorProducts.includes(g)
    );

    if (gridSemColor.length > 0) {
      let cor = colors.find((c) => c.id === gridSemColor[0]);
      Swal.fire(
        "Atenção!",
        "Você não pode excluir a cor " +
          cor.label +
          " pois existem produtos atrelados a ela.",
        "warning"
      );
      return false;
    }
    return true;
  };
  const handleChangeColor = (e) => {
    if (e.target.checked) {
      setColorsCompany((prev) => [...prev, parseInt(e.target.value)]);
    } else {
      const existProduct = grid.find(
        (g) => g.colorId === parseInt(e.target.value)
      );

      if (!existProduct) {
        setColorsCompany((prev) => [
          ...prev.filter((item) => !(item === parseInt(e.target.value))),
        ]);
        deleteColor(parseInt(e.target.value));
      } else {
        Swal.fire(
          "Atenção!",
          "Você não pode excluir essa cor pois existem produtos atrelados a ela.",
          "warning"
        );
      }
    }
  };
  const columns = [
    {
      field: "label",
      headerName: "Descrição",
      width: 250,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
      editable: true,
    },
    {
      field: "rgb",
      headerName: "Paleta",
      align: "center",
      headerAlign: "center",
      width: 310,
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        return (
          <div
            style={{
              height: "40px",
              width: "300px",
              backgroundColor: params.row.rgb,
              borderRadius: "15px",
            }}
          ></div>
        );
      },
    },
    {
      field: "colorId",
      headerName: "Cod. Cor",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      width: 0,
      align: "left",
      hideable: false,
    },
  ];
  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.error("error", error);
  }, []);
  const useFakeMutation = () => {
    return React.useCallback(
      (obj) =>
        new Promise(async (resolve, reject) => {
          if (currentUser.isAdmin) {
            const res = await userRequest.put(
              `${companyId}/products/colors/${obj.colorId}`,
              {
                label: obj.label,
              }
            );

            if (Object.keys(res.data).length > 0) {
              resolve(obj);
              getColorsCompany();
              Swal.fire("Parabéns!", "Cor alterada com sucesso!", "success");
            } else {
              reject(new Error("Erro atualizar cor"));
            }
          } else {
            Swal.fire(
              "Atenção!",
              "Você não tem permissão para editar a cor!",
              "warning"
            );
          }
        }),
      []
    );
  };
  const mutateRow = useFakeMutation();
  const AlterarLabel = React.useCallback(async (obj) => {
    const res = await mutateRow(obj);
    return obj;
  }, []);

  function CustomToolbar() {
    return <GridToolbarContainer></GridToolbarContainer>;
  }
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <div className="div_custom_UpdateList">
        <div className="div_custom_color">
          <div>
            <br></br>
            <br></br>
            <label>Tabela de Tamanhos</label>
            <br></br>
            <table className="custom-minigrid-size">
              <thead>
                <tr>
                  {sizes.map((size) => (
                    <td>{size.label}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {sizes.map((size) => (
                    <td>
                      <input
                        className="largerCheckbox"
                        type="checkbox"
                        value={size.sizeId}
                        onChange={handleChangeSize}
                        checked={
                          sizesCompany
                            ? sizesCompany.find(
                                (item) => item === size.sizeId
                                //item.sizeId === size.sizeId
                              )
                            : false
                        }
                      />
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
          <br></br>
          <label>Tabela de Cores</label>
          <br></br>
          {colors && colors.length > 1 ? (
            <DataGridPremium
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns id and lastname.
                    // Other columns will remain visible
                    colorId: false,
                  },
                },
              }}
              autoHeight
              sx={{
                fontFamily: "Manrope",
                fontWeight: "500",
                border: "1px solid #d7d8da",
              }}
              rowHeight={45}
              localeText={
                ptBRCore.components.MuiDataGrid.defaultProps.localeText
              }
              slots={{ toolbar: CustomToolbar }}
              slotProps={{
                panel: {
                  sx: {
                    top: "15px !important",
                    left: "50px !important",
                    position: "fixed !important",
                  },
                },
              }}
              getRowId={(row) => row.colorId}
              rows={colors}
              columns={columns}
              pagination
              autoPageSize
              checkboxSelection
              rowSelectionModel={colorsCompany}
              onRowSelectionModelChange={(itm) => {
                setColorsCompany(itm);
                const selectedIDs = new Set(itm);
                const select = colors.filter((row) =>
                  selectedIDs.has(row.colorId)
                );
              }}
              processRowUpdate={AlterarLabel}
              onProcessRowUpdateError={handleProcessRowUpdateError}
            />
          ) : (
            false
          )}
          <div className="div_custom_UpdateRight">
            {currentUser.isAdmin ? (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={cadastrarClick}
              >
                Cadastrar
              </Button>
            ) : (
              false
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
