/* eslint-disable jsx-a11y/alt-text */
import dayjs from "dayjs";
import "./collection.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../../App.css";
import { useCompany } from "../../components/context/CompanyContext";
import { userRequest } from "../../requestMethods";
import { titleSetRef } from "../../redux/titleRedux";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { generateFileUrl } from "../../requestMethods";
import { Button } from "@mui/material";

export default function Collection() {
  const [data, setData] = useState();
  const [dataIni, setDateIni] = useState();
  const [dataFim, setDateFim] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [readonly, setReadonly] = useState();

  const nav = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(titleSetRef({ title: "Acervo" }));
    setReadonly("7");
    if (companyId > 0) {
      getCollection();
    }
    setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
    setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (companyId > 0) {
      getCollection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIni, dataFim]);

  const getCollection = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/products/collection`,
          obj
        );
        setData(res.data);
      }
    } catch {}
  };

  //   try {
  //     let obj = {
  //       start: dataIni,
  //       end: dataFim,
  //     };

  //     const res = await userRequest.post(`${companyId}/eventUsers/share`, obj);

  //     setSharedQuantity(res.data);
  //   } catch {}
  // };

  const handleChangeStart = (e) => {
    setDateIni(e.target.value);
  };
  const handleChangeEnd = (e) => {
    setDateFim(e.target.value);
  };
  const handleChange = (event) => {
    setReadonly(event.target.value);
    // eslint-disable-next-line default-case
    switch (event.target.value) {
      case "0":
        setDateIni(dayjs(new Date()).format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "7":
        setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "15":
        setDateIni(dayjs(new Date()).add(-15, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "30":
        setDateIni(dayjs(new Date()).add(-30, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
    }
  };

  return (
    <div className="div_home_Container">
      <div className="datePicker">
        <label className="labelPeriodo">
          Filtrar:
          <input
            type="date"
            id="startDate"
            value={dataIni}
            name="trip-start"
            min="2023-01-01"
            onChange={handleChangeStart}
            disabled={readonly !== "F"}
          />
          <input
            type="date"
            id="endDate"
            value={dataFim}
            name="trip-start"
            min="2023-01-01"
            onChange={handleChangeEnd}
            disabled={readonly !== "F"}
          />
        </label>
      </div>
      <div className="column">
        <input
          type="radio"
          id="30"
          value="30"
          onChange={handleChange}
          checked={readonly === "30"}
        />
        <label for="30">30 dias</label>
        <input
          type="radio"
          id="15"
          value="15"
          onChange={handleChange}
          checked={readonly === "15"}
        />
        <label for="15">15 dias</label>
        <input
          type="radio"
          id="7"
          value="7"
          onChange={handleChange}
          checked={readonly === "7"}
        />
        <label for="7">7 dias</label>
        <input
          type="radio"
          id="0"
          value="0"
          onChange={handleChange}
          checked={readonly === "0"}
        />
        <label for="0">Hoje</label>
        <input
          type="radio"
          id="0"
          value="F"
          onChange={handleChange}
          checked={readonly === "F"}
        />
        <label for="0">Filtro</label>
      </div>
      <table width="100%">
        <tr>
          <div>
            {data &&
              data.map((item) =>
                item.images.map((img) => (
                  <img
                    className="imgSize"
                    src={generateFileUrl(img)}
                    loading="lazy"
                  />
                ))
              )}
          </div>
        </tr>
      </table>
    </div>
  );
}
