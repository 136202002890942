/* eslint-disable jsx-a11y/alt-text */
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../../App.css";
import { useCompany } from "../../components/context/CompanyContext";
import clientesColorido from "../../icons/clientesColorido.svg";
import produtosColorido from "../../icons/produtosColorido.svg";
import vendasColorida from "../../icons/vendasColorido.svg";
import { generateFileUrl, userRequest } from "../../requestMethods";
import { formatPricing } from "../../util/helper";
import "./report.css";
import { titleSetRef } from "../../redux/titleRedux";
export default function HomeReport() {
  const [orders, setOrders] = useState();
  const [customers, setCustomers] = useState();
  const [carts, setCarts] = useState();
  const [stockOver, setStockOver] = useState();
  const [stock, setStock] = useState();
  const [prdBestSeller, setPrdBestSeller] = useState();
  const [prdWithoutOrder, setPrdWithoutOrder] = useState([]);
  const [dataIni, setDateIni] = useState();
  const [dataFim, setDateFim] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [readonly, setReadonly] = useState();
  const [payments, setPayments] = useState();
  const nav = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(titleSetRef({ title: "Home" }));
    setReadonly("7");
    if (!currentUser) {
      nav(`../login`);
    }
    if (companyId > 0) {
      getCustomers();
      getCarts();
      getOrders();
      getStock();
      getStockOver();
      getProductsBestSeller();
      getwithoutOrder();
      getPayments();
    }
    setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
    setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (companyId > 0) {
      getCustomers();
      getCarts();
      getOrders();
      getProductsBestSeller();
      getwithoutOrder();
      getPayments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIni, dataFim]);

  const getCustomers = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customers`,
          obj
        );

        setCustomers(res.data);
      }
    } catch {}
  };
  const getPayments = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportPayments/amountPayments`,
          obj
        );

        setPayments(res.data);
      }
    } catch {}
  };
  const getCarts = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCarts/carts`,
          obj
        );
        setCarts(res.data);
      }
    } catch {}
  };
  const getOrders = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrders/orders`,
          obj
        );
        setOrders(res.data);
      }
    } catch {}
  };
  const getProductsBestSeller = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrders/productsBestSeller`,
          obj
        );
        setPrdBestSeller(res.data);
      }
    } catch {}
  };

  const getStock = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportProducts/prodstock`
      );

      setStock(res.data);
    } catch {}
  };
  const getStockOver = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportProducts/stockOver`
      );
      setStockOver(res.data);
    } catch {}
  };
  const getwithoutOrder = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportProducts/withoutOrder`,
          obj
        );

        setPrdWithoutOrder(res.data);
      }
    } catch {}
  };
  // const getShares = async () => {
  //   try {
  //     let obj = {
  //       start: dataIni,
  //       end: dataFim,
  //     };

  //     const res = await userRequest.post(`${companyId}/eventUsers/share`, obj);

  //     setSharedQuantity(res.data);
  //   } catch {}
  // };

  const handleChangeStart = (e) => {
    setDateIni(e.target.value);
  };
  const handleChangeEnd = (e) => {
    setDateFim(e.target.value);
  };
  const handleChange = (event) => {
    setReadonly(event.target.value);
    // eslint-disable-next-line default-case
    switch (event.target.value) {
      case "0":
        setDateIni(dayjs(new Date()).format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "7":
        setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "15":
        setDateIni(dayjs(new Date()).add(-15, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "30":
        setDateIni(dayjs(new Date()).add(-30, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
    }
  };

  return (
    <div className="div_home_Container">
      <div>
        <div>
          <img
            className="custom_banner"
            src={generateFileUrl("bannerAdmin/banner.png")}
          />
        </div>
        <br></br>
        <div className="datePicker">
          <label className="labelPeriodo">
            Filtrar:
            <input
              type="date"
              id="startDate"
              value={dataIni}
              name="trip-start"
              min="2023-01-01"
              onChange={handleChangeStart}
              disabled={readonly !== "F"}
            />
            <input
              type="date"
              id="endDate"
              value={dataFim}
              name="trip-start"
              min="2023-01-01"
              onChange={handleChangeEnd}
              disabled={readonly !== "F"}
            />
          </label>
        </div>
        <div className="column">
          <input
            type="radio"
            id="30"
            value="30"
            onChange={handleChange}
            checked={readonly === "30"}
          />
          <label for="30">30 dias</label>
          <input
            type="radio"
            id="15"
            value="15"
            onChange={handleChange}
            checked={readonly === "15"}
          />
          <label for="15">15 dias</label>
          <input
            type="radio"
            id="7"
            value="7"
            onChange={handleChange}
            checked={readonly === "7"}
          />
          <label for="7">7 dias</label>
          <input
            type="radio"
            id="0"
            value="0"
            onChange={handleChange}
            checked={readonly === "0"}
          />
          <label for="0">Hoje</label>
          <input
            type="radio"
            id="0"
            value="F"
            onChange={handleChange}
            checked={readonly === "F"}
          />
          <label for="0">Filtro</label>
        </div>
      </div>
      <table width="100%" className="tableSpace">
        <tbody>
          <tr>
            <td width="50%">
              <div className="div_home_custom">
                <table className="table">
                  <tbody>
                    <tr>
                      <td align="left" width="100%">
                        <tr>
                          <td width="23%">
                            <img src={vendasColorida} />
                          </td>
                          <td width="77%">
                            <tr className="labelTitle">Vendas</tr>
                            <tr className="labelMsgData">
                              {readonly === "F"
                                ? "Exibindo dados do filtro de data"
                                : readonly === "0"
                                ? "Exibindo dados de hoje"
                                : "Exibindo dados dos últimos " +
                                  readonly +
                                  " dias"}
                            </tr>
                          </td>
                        </tr>
                      </td>
                      <td align="left" width="0%">
                        {" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  cellSpacing="1"
                  cellPadding="2"
                  align="center"
                  className="table"
                >
                  <tbody>
                    <tr>
                      <td align="left" className="left">
                        Carrinho({carts && carts.quantity ? carts.quantity : 0})
                      </td>
                      <td align="right" className="right">
                        {carts && carts.total
                          ? formatPricing(carts.total)
                          : "R$ 0,00"}
                      </td>
                    </tr>
                    <tr>
                      <td align="left" className="left">
                        Pedidos finalizados(
                        {orders && orders.cFinalizado ? orders.cFinalizado : 0})
                      </td>
                      <td align="right" className="right">
                        {orders && orders.aFinalizado
                          ? formatPricing(orders.aFinalizado)
                          : "R$ 0,00"}
                      </td>
                    </tr>
                    <tr>
                      <td align="left" className="left">
                        Pedidos pendentes(
                        {orders && orders.cPendente ? orders.cPendente : 0})
                      </td>
                      <td align="right" className="right">
                        {orders && orders.aPendente
                          ? formatPricing(orders.aPendente)
                          : "R$ 0,00"}
                      </td>
                    </tr>
                    <tr>
                      <td align="left" className="left">
                        Total pedidos(
                        {orders && orders.cTotal ? orders.cTotal : 0})
                      </td>
                      <td align="right" className="right">
                        {orders && orders.aTotal
                          ? formatPricing(orders.aTotal)
                          : "R$ 0,00"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="details">
                  <Link className="details" to={`../tabdashboard?tab=0`}>
                    Ver mais detalhes
                  </Link>
                </div>
              </div>
            </td>
            <td width="50%">
              <div className="div_home_custom">
                <table className="table">
                  <tbody>
                    <tr>
                      <td align="left" width="100%">
                        <tr>
                          <td width="23%">
                            <img src={clientesColorido} />
                          </td>
                          <td width="77%">
                            <tr className="labelTitle">Clientes</tr>
                            <tr className="labelMsgData">
                              {readonly === "F"
                                ? "Exibindo dados do filtro de data"
                                : readonly === "0"
                                ? "Exibindo dados de hoje"
                                : "Exibindo dados dos últimos " +
                                  readonly +
                                  " dias"}
                            </tr>
                          </td>
                        </tr>
                      </td>
                      <td align="left" width="0%">
                        {" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  cellSpacing="1"
                  cellPadding="2"
                  align="center"
                  className="table"
                >
                  <tbody>
                    <tr>
                      <td align="left" className="left">
                        Novos clientes
                      </td>
                      <td align="right" className="right">
                        {customers && customers.newCustomer
                          ? customers.newCustomer
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td align="left" className="left">
                        Acesso aos catálogos
                      </td>
                      <td align="right" className="right">
                        {customers && customers.acess ? customers.acess : 0}%
                      </td>
                    </tr>
                    <tr>
                      <td align="left" className="left">
                        Qtde clientes
                      </td>
                      <td align="right" className="right">
                        {customers && customers.qtdeCustomer
                          ? customers.qtdeCustomer
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td align="left" className="left">
                        <span>Clientes ativos</span>
                        <span className="labelMsgDataAtivos">
                          {" "}
                          (Últimos 90 dias)
                        </span>
                      </td>
                      <td align="right" className="right">
                        {customers && customers.ativos ? customers.ativos : 0}%
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="details">
                  <Link className="details" to={`../tabdashboard?tab=1`}>
                    Ver mais detalhes
                  </Link>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td width="50%">
              <div className="div_home_custom">
                <table className="table">
                  <tbody>
                    <tr>
                      <td align="left">
                        <tr>
                          <td width="23%">
                            <img src={produtosColorido} />
                          </td>
                          <td width="77%">
                            <tr className="labelTitle">Produtos</tr>
                            <tr className="labelMsgData">
                              *
                              {readonly === "F"
                                ? "Exibindo dados do filtro de data"
                                : readonly === "0"
                                ? "Exibindo dados de hoje"
                                : "Exibindo dados dos últimos " +
                                  readonly +
                                  " dias"}
                            </tr>
                          </td>
                        </tr>
                      </td>
                      <td align="left"> </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  cellSpacing="1"
                  cellPadding="2"
                  align="center"
                  className="table"
                >
                  <tbody>
                    <tr>
                      <td align="left" className="left">
                        Mais vendidos(
                        {prdBestSeller && prdBestSeller.count
                          ? prdBestSeller.count
                          : 0}
                        )*
                      </td>
                      <td align="right" className="right">
                        {prdBestSeller && prdBestSeller.ref
                          ? prdBestSeller.ref
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td align="left" className="left">
                        Sem venda*
                      </td>
                      <td align="right" className="right">
                        {prdWithoutOrder ? prdWithoutOrder.length : 0}
                      </td>
                    </tr>
                    <tr>
                      <td align="left" className="left">
                        Visível sem estoque
                      </td>
                      <td align="right" className="right">
                        {stock && stock.stockOver ? stock.stockOver : 0}
                      </td>
                    </tr>
                    <tr>
                      <td align="left" className="left">
                        Invisível com estoque
                      </td>
                      <td align="right" className="right">
                        {stock && stock.completedVisible
                          ? stock.completedVisible + stock.incompletedVisible
                          : 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="details">
                  <Link className="details" to={`../tabdashboard?tab=2`}>
                    Ver mais detalhes
                  </Link>
                </div>
              </div>
            </td>
            <td width="50%">
              <div className="div_home_custom">
                <table className="table">
                  <tbody>
                    <tr>
                      <td align="left">
                        <tr>
                          <td width="23%">
                            <img src={produtosColorido} />
                          </td>
                          <td width="77%">
                            <tr className="labelTitle">Pagamentos</tr>
                            <tr className="labelMsgData">
                              *
                              {readonly === "F"
                                ? "Exibindo dados do filtro de data"
                                : readonly === "0"
                                ? "Exibindo dados de hoje"
                                : "Exibindo dados dos últimos " +
                                  readonly +
                                  " dias"}
                            </tr>
                          </td>
                        </tr>
                      </td>
                      <td align="left"> </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  cellSpacing="1"
                  cellPadding="2"
                  align="center"
                  className="table"
                >
                  <tbody>
                    <tr>
                      <td align="left" className="left">
                        Mooda
                      </td>
                      <td align="right" className="right">
                        {payments && payments.mooda
                          ? formatPricing(payments.mooda)
                          : "R$ 0,00"}
                      </td>
                    </tr>
                    <tr>
                      <td align="left" className="left">
                        Pix
                      </td>
                      <td align="right" className="right">
                        {payments && payments.pix
                          ? formatPricing(payments.pix)
                          : "R$ 0,00"}
                      </td>
                    </tr>
                    <tr>
                      <td align="left" className="left">
                        Dinheiro
                      </td>
                      <td align="right" className="right">
                        {payments && payments.dinheiro
                          ? formatPricing(payments.dinheiro)
                          : "R$ 0,00"}
                      </td>
                    </tr>
                    <tr>
                      <td align="left" className="left">
                        Cartão de Crédito
                      </td>
                      <td align="right" className="right">
                        {payments && payments.creditCard
                          ? formatPricing(payments.creditCard)
                          : "R$ 0,00"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="details">
                  <Link className="details" to={`../tabdashboard?tab=4`}>
                    Ver mais detalhes
                  </Link>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
