import { useEffect, useState, useRef } from "react";
import "./company.css";
import { generateFileUrl, userRequest } from "../../requestMethods";
import "../../global.css";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../components/context/CompanyContext";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Container from "@mui/material/Container";
import { formatPricing, formatPricingDatabase } from "../../util/helper";
export default function Company() {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [company, setCompany] = useState("");
  const [state, setState] = useState("");
  const [showPrice, setShowPrice] = useState();
  const [states, setStates] = useState([]);
  const [file, setFile] = useState();
  const [fileList, setFileList] = useState();
  const [inputs, setInputs] = useState();
  const [filesIni, setFilesIni] = useState();
  const [numAddress, setNumAddress] = useState();
  const [complement, setComplement] = useState();
  const [rzSocial, setRzSocial] = useState();
  const [cnpj_cpf, setCnpj_cpf] = useState();
  const [contact, setContact] = useState();
  const [street, setStreet] = useState();
  const [cep, setCep] = useState();
  const [district, setDistrict] = useState();
  const [city, setCity] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const inputRef = useRef();
  const inputRefname = useRef("");
  const inputRefcontact = useRef("");
  const inputRefemail = useRef("");
  const inputRefabout = useRef("");
  const inputRefPolicy = useRef("");
  const inputRefMinprd = useRef("");
  const inputRefMinOrder = useRef("");
  const inputRefpixel = useRef("");
  const inputRefgmt = useRef("");
  const { imgLogo, companyId } = useCompany();

  useEffect(() => {
    if (companyId > 0) {
      getCompany();
      getStates();
    }
  }, [companyId]);

  useEffect(() => {}, [file]);

  useEffect(() => {
    if (company) {
      if (company.address) {
        setStreet(company.address.street ? company.address.street : null);
        setNumAddress(
          company.address.numAddress ? company.address.numAddress : null
        );
        setComplement(
          company.address.complement ? company.address.complement : null
        );
        setCep(company.address.cep ? company.address.cep : null);
        setState(company.address.state ? company.address.state : null);
        setDistrict(company.address.district ? company.address.district : null);
        setCity(company.address.city ? company.address.city : null);
      }

      if (company.cnpj) {
        setCnpj_cpf(company.cnpj);
      }
      if (company.contact) {
        setContact(company.contact);
      }
      if (company.rzSocial) {
        setRzSocial(company.rzSocial);
      }
      if (imgLogo) {
        setFilesIni(true);
      }
      setFile(imgLogo);
    }
    if (company.configRules && company.configRules.showPrice) {
      setShowPrice(company.configRules.showPrice);
    }
  }, [company]);

  const getCompany = async () => {
    try {
      const res = await userRequest.get(`${companyId}/company`);

      setCompany(res.data);
    } catch (error) {}
  };
  const getStates = async () => {
    const res = await userRequest.get(`${companyId}/customers/state`);

    setStates(res.data);
  };
  const handleChangeCNPJ = (e) => {
    if (e.target.value.length === 14) {
      getCNPJ(e.target.value.trim());
    }
  };
  const handleChangeStreet = (e) => {
    setStreet(e.target.value);
  };
  const handleChangeNumAddress = (e) => {
    setNumAddress(e.target.value);
  };
  const handleChangeComplement = (e) => {
    setComplement(e.target.value);
  };

  const handleChangeCity = (e) => {
    setCity(e.target.value);
  };
  const getCep = async (cep) => {
    const res = await userRequest.get(`${companyId}/customers/cep/${cep}`);

    setStreet(res.data.logradouro);
    setCep(cep);
    setState(res.data.uf);
    setDistrict(res.data.bairro);
    setCity(res.data.localidade);
  };
  const getCNPJ = async (cnpj) => {
    const res = await userRequest.get(`${companyId}/customers/cnpj/${cnpj}`);

    setRzSocial(res.data.razao_social);
    setCnpj_cpf(cnpj);
  };
  const validate = () => {
    if (
      inputRefname.current.value === "" ||
      inputRefname.current.value === undefined
    ) {
      return false;
    }
    if (
      inputRefemail.current.value === "" ||
      inputRefemail.current.value === undefined
    ) {
      return false;
    }
    if (
      inputRefcontact.current.value === "" ||
      inputRefcontact.current.value === undefined
    ) {
      return false;
    }
    if (cnpj_cpf === "" || cnpj_cpf === undefined) {
      return false;
    }

    if (contact === "" || contact === undefined) {
      return false;
    }
    if (!street || street === "" || street === undefined) {
      return false;
    }
    if (!cep || cep === "" || cep === undefined) {
      return false;
    }
    if (!state || state === "" || state === undefined) {
      return false;
    }
    if (!state) {
      return false;
    }
    return true;
  };
  const updateClick = async (event) => {
    if (validate) {
      let upload = true;
      if (!filesIni) {
        if (fileList.length === 1) {
          upload = handleUpload();
        } else {
          Swal.fire("Atenção!", "Você pode ter apenas 1 logo!", "warning");
        }
      }
      if (upload) {
        Update();
      }
    } else {
      Swal.fire(
        "Atenção!",
        "Todos os campos devem ser preenchidos!",
        "warning"
      );
    }
  };
  const Update = async (event) => {
    try {
      const res = await userRequest.put(`${companyId}/company/`, {
        name: inputRefname.current.value,
        cnpj: cnpj_cpf,
        rzSocial: rzSocial,
        contact: contact,
        email: inputRefemail.current.value,
        lastUserUpdate: currentUser.userId,
        address: {
          street: street,
          numAddress: numAddress,
          complement: complement,
          cep: cep,
          state: state,
          district: district,
          city: city,
        },
        about: inputRefabout.current.value,
        exchangePolicy: inputRefPolicy.current.value,
        configRules: {
          minProducts: parseInt(inputRefMinprd.current.value),
          showPrice: showPrice,
          minVlrOrder: formatPricingDatabase(inputRefMinOrder.current.value),
        },
        pixel: inputRefpixel.current.value,
        digitalMkt: {
          pixel: inputRefpixel.current.value,
          gmt: inputRefgmt.current.value,
        },
      });
      if (Object.keys(res.data).length > 0) {
        Swal.fire(
          "Parabéns!",
          "Dados da empresa alterado com sucesso!",
          "success"
        );
      } else {
        Swal.fire("Atenção!", "Erro ao alterar daddos!", "error");
      }
    } catch (error) {}
  };

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleChangeState = (e) => {
    setState(e.target.value);
  };
  const handleOnclick = (event) => {
    event.preventDefault();
    inputRef.current.click();
  };
  const handleUpload = async (event) => {
    const formData = new FormData();

    if (fileList.length > 0) {
      for (var i = 0; i < fileList.length; i++) {
        const photo = fileList[i];
        formData.append("image", photo);
      }
    }
    const res = userRequest.post(`${companyId}/products/images/logo`, formData);

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };
  const handleOnDelete = (event) => {
    setFilesIni(false);
    setFile();
    setFileList();

    const res = userRequest.delete(`${companyId}/products/images/deletelogo`);

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };
  const handleOnChange = (event) => {
    setFileList(event.target.files);
    Array.from(event.target.files).map((item) => {
      setFile(item);
    });

    setFilesIni(false);
  };
  const handleChangeEnabled = (e) => {
    setShowPrice(e.target.checked);
  };
  const handleChangeContact = (e) => {
    setContact(e.target.value);
  };
  const handleChangeRz = (e) => {
    setRzSocial(e.target.value);
  };
  const handleChangeCEP = (e) => {
    if (e.target.value.length === 8) {
      getCep(e.target.value.trim().replace("-", ""));
    }
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <div className="div_custom_UpdateList">
        <span className="div_custom_UpdateTitle">Dados da Empresa</span>
        <div className="div_custom_UpdateItem">
          <label>Logo</label>
        </div>
        <div>
          {file && filesIni !== undefined ? (
            <div className="uploads">
              <img
                className="bannerUpload"
                src={
                  filesIni
                    ? generateFileUrl(file.newName ? file.newName : file)
                    : URL.createObjectURL(file)
                }
              />

              <div>
                {currentUser.isAdmin ? (
                  <Button
                    variant="outlined"
                    className="custom_mui_action"
                    onClick={handleOnDelete}
                  >
                    Excluir Logo
                  </Button>
                ) : (
                  false
                )}
              </div>
            </div>
          ) : (
            <div>
              <input
                type="file"
                multiple
                onChange={handleOnChange}
                hidden
                accept="image/png, image/jpeg"
                ref={inputRef}
              />
              <Button
                variant="outlined"
                className="custom_mui_action"
                onClick={handleOnclick}
              >
                Selecione o logo
              </Button>
            </div>
          )}
        </div>
        <div className="div_custom_UpdateItem">
          <label>Nome</label>
          <input
            type="text"
            name="name"
            defaultValue={company ? company.name || "" : ""}
            className="div_custom_UpdateInput"
            onChange={handleChange}
            maxLength="200"
            ref={inputRefname}
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>CNPJ</label>
          <input
            type="text"
            pattern="\d*"
            name="cnpj_cpf"
            className="div_custom_UpdateInput"
            defaultValue={cnpj_cpf ? cnpj_cpf : ""}
            onChange={handleChangeCNPJ}
            maxLength="14"
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>Razão Social</label>
          <input
            type="text"
            name="rzSocial"
            className="div_custom_UpdateInput"
            onChange={handleChangeRz}
            defaultValue={rzSocial ? rzSocial : ""}
            maxLength="200"
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>Telefone</label>
          <input
            type="text"
            name="contact"
            onChange={handleChangeContact}
            className="div_custom_UpdateInput"
            defaultValue={contact ? contact : ""}
            maxLength="12"
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>Email</label>
          <input
            type="email"
            name="email"
            className="div_custom_UpdateInput"
            defaultValue={company ? company.email || "" : ""}
            onChange={handleChange}
            maxLength="200"
            ref={inputRefemail}
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>CEP</label>
          <input
            type="text"
            pattern="\d*"
            name="cep"
            onChange={handleChangeCEP}
            className="div_custom_UpdateInput"
            defaultValue={cep ? cep : ""}
            maxLength="8"
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>Endereço</label>
          <input
            type="text"
            name="street"
            onChange={handleChangeStreet}
            className="div_custom_UpdateInput"
            defaultValue={street ? street : ""}
            maxLength="200"
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>Numero</label>
          <input
            type="text"
            name="numAddress"
            onChange={handleChangeNumAddress}
            className="div_custom_UpdateInput"
            defaultValue={numAddress ? numAddress : ""}
            maxLength="8"
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>Complemento</label>
          <input
            type="text"
            name="complement"
            onChange={handleChangeComplement}
            className="div_custom_UpdateInput"
            defaultValue={complement ? complement : ""}
            maxLength="8"
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>Estado</label>
          <select
            className="div_custom_Select"
            name="state"
            value={state ? state : ""}
          >
            <option value=""></option>
            {states
              ? states.map((item) => (
                  <option value={item.stateId}>{item.state}</option>
                ))
              : false}
          </select>
        </div>
        <div className="div_custom_UpdateItem">
          <label>Cidade</label>
          <input
            type="text"
            name="city"
            onChange={handleChangeCity}
            className="div_custom_UpdateInput"
            defaultValue={city ? city : ""}
            maxLength="8"
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>Sobre a Marca</label>
          <textarea
            rows="10"
            type="text"
            name="about"
            className="div_custom_UpdateInput_textArea"
            defaultValue={company ? company.about : ""}
            onChange={handleChange}
            maxLength="5000"
            ref={inputRefabout}
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>Política de Troca</label>
          <textarea
            rows="10"
            type="text"
            name="exchangePolicy"
            className="div_custom_UpdateInput_textArea"
            defaultValue={company ? company.exchangePolicy : ""}
            onChange={handleChange}
            maxLength="5000"
            ref={inputRefPolicy}
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>Quantidade mínima de produtos a ser vendidos</label>
          <input
            type="text"
            name="city"
            onChange={handleChange}
            className="div_custom_UpdateInput"
            defaultValue={
              company && company.configRules
                ? company.configRules.minProducts || ""
                : ""
            }
            maxLength="8"
            ref={inputRefMinprd}
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>Valor mínimo do pedido</label>
          <input
            type="text"
            name="city"
            onChange={handleChange}
            className="div_custom_UpdateInput"
            defaultValue={
              company && company.configRules && company.configRules.minVlrOrder
                ? formatPricing(company.configRules.minVlrOrder) || ""
                : ""
            }
            maxLength="8"
            ref={inputRefMinOrder}
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>Pixel</label>
          <input
            type="text"
            name="pixel"
            className="div_custom_UpdateInput"
            defaultValue={
              company && company.digitalMkt
                ? company.digitalMkt.pixel || ""
                : ""
            }
            onChange={handleChange}
            maxLength="50"
            ref={inputRefpixel}
          />
        </div>
        <div className="div_custom_UpdateItem">
          <label>Google Tag Manager</label>
          <input
            type="text"
            name="gmt"
            className="div_custom_UpdateInput"
            defaultValue={
              company && company.digitalMkt ? company.digitalMkt.gmt || "" : ""
            }
            onChange={handleChange}
            maxLength="15"
            ref={inputRefgmt}
          />
        </div>
        {/* <div className="div_custom_UpdateItem">
            <label>Exibir os preços</label>
            <input
              className="largerCheckbox"
              type="checkbox"
              name="enabled"
              checked={showPrice}
              onChange={handleChangeEnabled}
            />
          </div> */}
        <br></br>
        <div className="div_custom_UpdateRight">
          {currentUser.isAdmin ? (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<SaveIcon />}
              onClick={updateClick}
            >
              Cadastrar
            </Button>
          ) : (
            false
          )}
        </div>
      </div>
    </Container>
  );
}
