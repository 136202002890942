import "../../global.css";
import "./customerList.css";
import { setState } from "../../redux/dataGridRedux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ptBR } from "@mui/x-data-grid/locales";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarColumnsButton,
  useGridApiRef,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import * as React from "react";
import { DeleteOutline } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethods";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { formatTimeDDMMYYY } from "../../util/helper";
import Loading from "../../components/loading/loading";
import { useCompany } from "../../components/context/CompanyContext";
import Swal from "sweetalert2";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import MessageIcon from "@mui/icons-material/Message";
import { formatPricing } from "../../util/helper";
import { useDispatch, useSelector } from "react-redux";
import cadastro from "../../icons/cadastro.svg";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { titleSetRef } from "../../redux/titleRedux";
const style = {
  background: "white",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  padding: "60px",
  borderRadius: "15px",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

export default function CustomerList() {
  const currentUser = useSelector((state) => state.user.currentUser);
  //  console.log("currentUser",currentUser);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalCustomer, setModalCustomer] = useState();
  const [name, setName] = useState();
  const { companyId } = useCompany();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const apiRef = useGridApiRef();
  const dataGridState = useSelector((state) => state.DataGridPremium.stateGrid);

  function filterModelChanged() {
    dispatch(
      setState({
        initialState: apiRef.current.exportState(),
      })
    );
  }

  useEffect(() => {
    if (companyId > 0) {
      getCustomers();
      dispatch(titleSetRef({ title: "Clientes" }));
    }
  }, [companyId]);

  useEffect(() => {
    if (modalCustomer) {
      setOpen(true);
    }
  }, [modalCustomer]);

  const getCustomers = async () => {
    try {
      let url = "";
      setIsLoading(true);
      if (currentUser.isAdmin) {
        url = `${companyId}/customers`;
      } else {
        url = `${companyId}/customers/managedBy/${currentUser.userId}`;
      }

      const res = await userRequest.get(url);
      if (res.data) {
        setData(res.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const deleteCustomer = async (customer) => {
    const res = await userRequest.delete(`${companyId}/customers/${customer}`);
    if (res.data > 0) {
      getCustomers();
    } else {
      Swal.fire("Atenção!", "Erro ao excluir cliente!", "error");
    }
  };

  const deleteLogico = async (customer) => {
    try {
      const res = await userRequest.put(`${companyId}/customers/${customer}`, {
        deleted: new Date(),
        lastUserUpdate: currentUser.userId,
      });
      if (Object.keys(res.data).length > 0) {
        getCustomers();
      } else {
        Swal.fire("Atenção!", "Erro ao excluir cliente!", "error");
      }
    } catch (error) {}
  };
  const onButtonDeleteClick = (e, row) => {
    if (currentUser.isAdmin) {
      Swal.fire({
        title: "Tem certeza que deseja excluir?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EE8253",
        confirmButtonText: "Sim, excluir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //deleteCustomer(row);
          deleteLogico(row);
        }
      });
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para excluir cliente!",
        "warning"
      );
    }
  };
  const getCNPJ = async (cnpj) => {
    const res = await userRequest.get(`${companyId}/customers/cnpj/${cnpj}`);
    setModalCustomer(res.data);
  };
  const onButtonEditarClick = (e, row) => {
    nav(`${row}`);
  };
  const handleOpen = (e, cnpj, name) => {
    getCNPJ(cnpj);
    setName(name);
  };
  const nav = useNavigate();
  const addRow = () => {
    nav(`new`);
  };
  const handleChangePremium = async (e, row) => {
    await userRequest.put(`${companyId}/customers/${row}`, {
      presales: e.target.checked,
    });
  };
  const columns = [
    {
      field: "customerId",
      headerName: "Cod. Cliente",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "editar",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={(e) => onButtonEditarClick(e, params.row.customerId)}
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "presales",
      headerName: "Premium",
      width: 100,
      align: "center",
      headerClassName: "dataGridMuiHeader",
      headerAlign: "center",
      display: "flex",
      renderCell: (params) => {
        return (
          <input
            className="star"
            type="checkbox"
            name="presales"
            checked={params.value}
            onChange={(e) => handleChangePremium(e, params.row.customerId)}
          />
        );
      },
    },
    {
      field: "enabled",
      headerName: "Visão dos Preços",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        switch (params.value) {
          case "Aprovado":
            return (
              <>
                <IconButton
                  onClick={(e) =>
                    handleOpen(e, params.row.cnpj_cpf, params.row.name)
                  }
                >
                  <CheckCircleOutlineOutlinedIcon color="success" />
                </IconButton>
              </>
            );
            break;
          case "Negado":
            return (
              <>
                <IconButton
                  onClick={(e) =>
                    handleOpen(e, params.row.cnpj_cpf, params.row.name)
                  }
                >
                  <RemoveCircleOutlineOutlinedIcon sx={{ color: "red" }} />
                </IconButton>
              </>
            );
            break;
          default:
            return (
              <>
                <IconButton
                  onClick={(e) =>
                    handleOpen(e, params.row.cnpj_cpf, params.row.name)
                  }
                >
                  <ReportGmailerrorredIcon sx={{ color: "#EE8253" }} />
                </IconButton>
              </>
            );
        }
      },
    },
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "owner_cpf",
      headerName: "CPF",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "cnpj_cpf",
      headerName: "CNPJ",
      width: 160,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "rzSocial",
      headerName: "Razão Social",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "contact",
      headerName: "Contato",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "managedBy",
      headerName: "Vendedor",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "onlineStore",
      headerName: "Inta ou Loja virtual",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "orderAmount",
      headerName: "Valor último pedido",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "orderDate",
      headerName: "Data último pedido",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "cartTotal",
      headerName: "Valor carrinho",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "cartDate",
      headerName: "Data carrinho",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "createdAt",
      headerName: "Criado",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "updatedAt",
      headerName: "Atualizado",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "acessAt",
      headerName: "Último Acesso",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "message",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        if (params.row.managedBy !== null) {
          return (
            <IconButton
              target="_blank"
              href={`https://wa.me//55${
                params.row.contactManagedBy
              }?text=Cliente%20${
                params.row.name
              }%20já%20liberado%20para%20compras,segue:
            %0DNome:${params.row.name}
            %0DRzSocial:${params.row.rzSocial ? params.row.rzSocial : ""}
            %0DCPF:${params.row.owner_cpf ? params.row.owner_cpf : ""}
            %0DCNPJ:${params.row.cnpj_cpf ? params.row.cnpj_cpf : ""}
            %0DContato:${params.row.contact ? params.row.contact : ""}
            %0DEmail:${params.row.email ? params.row.email : ""}
            %0DEnd:${params.row.address.street ? params.row.address.street : ""}
            %0DComplemeto:${
              params.row.address.complement ? params.row.address.complement : ""
            }
            %0DCidade:${params.row.address.city ? params.row.address.city : ""}
            %0DUF:${params.row.address.uf ? params.row.address.uf : ""}
            %0DInstaLojaVirtual:${
              params.row.onlineStore ? params.row.onlineStore : ""
            }
            `}
            >
              <MessageIcon />
            </IconButton>
          );
        }
      },
    },
    {
      field: "deletar",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(e) => onButtonDeleteClick(e, params.row.customerId)}
          >
            <DeleteOutline />
          </IconButton>
        );
      },
    },
  ];
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <div className="div_custom_Container">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <>
            <table className="table">
              <tbody>
                <tr>
                  <td align="left" width="100%">
                    <tr>
                      <td>
                        <img src={cadastro} />
                      </td>
                      <td>
                        <tr className="labelTitle">{name}</tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left" width="0%">
                    {" "}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellspacing="1"
              cellpadding="2"
              align="center"
              className="table"
            >
              <tbody>
                <tr>
                  <td align="left" className="left">
                    Razão Social:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer ? modalCustomer.razao_social : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Socio:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer ? modalCustomer.socio : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Capital Social:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer
                      ? formatPricing(modalCustomer.capital_social)
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Porte:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer ? modalCustomer.porte : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Natureza Júridica:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer ? modalCustomer.natureza_juridica : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Qualificação Responsável:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer
                      ? modalCustomer.qualificacao_do_responsavel
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Endereço:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer && modalCustomer.end_empresa
                      ? modalCustomer.end_empresa +
                        "," +
                        modalCustomer.end_num_empresa
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Complemento:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer ? modalCustomer.end_comp_empresa : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Bairro:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer ? modalCustomer.end_bairro : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    CEP:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer ? modalCustomer.end_cep : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Telefone:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer && modalCustomer.end_ddd
                      ? "(" + modalCustomer.end_ddd + ")"
                      : ""}
                    {modalCustomer ? modalCustomer.end_tel : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Estado:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer ? modalCustomer.end_estado : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Cidade:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer ? modalCustomer.end_cidade : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Atividade Principal:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer ? modalCustomer.atividadePrincipal : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Inscrição Estadual:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer ? modalCustomer.inscricao_estadual : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Data inicio atividade:
                  </td>
                  <td align="left" className="right_black_List">
                    {modalCustomer
                      ? formatTimeDDMMYYY(modalCustomer.data_inicio_atividade)
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        </div>
      </Modal>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_UpdateList">
          <div className="div_custom_UpdateRight">
            <Button
              className="custom_add_top"
              variant="outlined"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={addRow}
              size="small"
            >
              Adicionar Cliente
            </Button>
          </div>
          <div className="div_custom_UpdateLeft">
            <div style={{ height: 900 }}>
              <DataGridPremium
                sx={{
                  fontFamily: "Manrope",
                  fontWeight: "500",
                  border: "0px",
                }}
                rowHeight={45}
                initialState={dataGridState.initialState}
                onFilterModelChange={filterModelChanged}
                apiRef={apiRef}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                  panel: {
                    sx: {
                      top: "15px !important",
                      left: "50px !important",
                      position: "fixed !important",
                    },
                  },
                }}
                getRowId={(row) => row.customerId}
                rows={data}
                disableSelectionOnClick
                columns={columns}
                pagination
                autoPageSize
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
