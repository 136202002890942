import "../../global.css";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import * as React from "react";
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethods";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatPricing } from "../../util/helper";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { formatTimeDDMMYYY, formatTime24hourFormat } from "../../util/helper";
import { useCompany } from "../../components/context/CompanyContext";
import Loading from "../../components/loading/loading";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import { DeleteOutline } from "@mui/icons-material";

export default function CartListItem() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useCompany();

  useEffect(() => {
    setIsLoading(true);
    if (companyId > 0) {
      getCarts();
    }
    setIsLoading(false);
  }, [companyId]);

  const getCarts = async () => {
    const res = await userRequest.post(`${companyId}/carts/find`, {});

    setData(res.data);
  };
  const deleteCart = async (customerId) => {
    const res = await userRequest.delete(`${companyId}/carts/${customerId}`);

    if (res.data > 0) {
      getCarts();
    } else {
      Swal.fire("Atenção!", "Erro ao excluir carrinho!", "error");
    }
  };
  const nav = useNavigate();
  const onButtonDeleteClick = (e, row) => {
    if (currentUser.isAdmin) {
      Swal.fire({
        title: "Tem certeza que deseja excluir?",
        text: "Isso não excluirá o carrinho do cliente!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EE8253",
        confirmButtonText: "Sim, excluir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          deleteCart(row);
        }
      });
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para excluir o carrinho!",
        "warning"
      );
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "contact",
      headerName: "Telefone",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "quantity",
      headerName: "Qtde Itens",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "total",
      headerName: "Valor",
      width: 120,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) => formatPricing(params?.value),
    },
    {
      field: "createdAt",
      headerName: "Criada em",
      width: 200,
      align: "left",
      valueFormatter: (params) => formatTime24hourFormat(params?.value),
      type: "date",
    },
    {
      field: "delete",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      display: "flex",
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(e) => onButtonDeleteClick(e, params.row.customerId)}
            variant="contained"
          >
            <DeleteOutline />
          </IconButton>
        );
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <div className="list">
      {isLoading ? (
        <Loading />
      ) : (
        <DataGridPremium
          autoHeight
          rowHeight={25}
          localeText={ptBRCore.components.MuiDataGrid.defaultProps.localeText}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            panel: {
              sx: {
                top: "15px !important",
                left: "50px !important",
                position: "fixed !important",
              },
            },
          }}
          getRowId={(row) => row.cartId}
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={8}
        />
      )}
    </div>
  );
}
