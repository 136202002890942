import { ContainerFile } from "../ContainerFile";
import { TrashButton } from "../TrashButton";
import { VideoSource } from "../VideoSource";
import { generateFileUrl } from "../../../../requestMethods";

export function FileBox({
  file,
  removeFileBeforeCreateProduct,
  handleOneDeleteFile,
  isImage,
  isNewFile,
}) {
  return (
    <ContainerFile>
      <TrashButton
        handleClick={
          isNewFile ? removeFileBeforeCreateProduct : handleOneDeleteFile
        }
        itemToDelete={file}
      />
      {isImage && (
        <img
          alt="Imagem do produto"
          className="productUploadImg2"
          src={isNewFile ? URL.createObjectURL(file) : generateFileUrl(file)}
        />
      )}

      {!isImage && (
        <VideoSource
          handleSrcFile={isNewFile ? URL.createObjectURL : generateFileUrl}
          file={file}
        />
      )}
    </ContainerFile>
  );
}
